import format from 'date-fns/format';
import APIUtils from '@livongo/utilities/system/api';
import {FORMAT_DATETIME_NO_TZ} from '../config';
import UserAPI from '../user/user-api';
import BloodSugarUtils from './blood-sugar-utils';

const COACH_COMMUNICATION_APP_ID = 5;
const CUSTOM_ALERTS_APP_ID = 11;

function roundFloat(num) {
    return parseFloat(parseFloat(num).toFixed(1));
}

function formatAlertPreferences(data) {
    return data.reduce((collection, {prefName, prefVal: value, ...other}) => {
        const key = BloodSugarUtils.preferenceKeys[prefName];

        collection[key] = {
            value,
            ...other,
        };

        return collection;
    }, {});
}

const BloodSugarAPI = {
    getReadings({start, end, count, tagsSource, sortKey}) {
        return APIUtils.get('v1/users/me/readings/bgs/data', {
            ...(start && {start: format(start, FORMAT_DATETIME_NO_TZ)}),
            ...(end && {end: format(end, FORMAT_DATETIME_NO_TZ)}),
            ...(count && {count}),
            ...(tagsSource && {tagsSource}),
            ...(sortKey && {sortKey}),
            tagsIsControl: false, // "control readings" are dummy data create by blood glucose devices, we must avoid that
        }).then(response => {
            return response.data.bgs;
        });
    },

    getStatistics({start, end}) {
        return APIUtils.get('v1/users/me/readings/bgs/stats/extended', {
            ...(start && {start: format(start, FORMAT_DATETIME_NO_TZ)}),
            ...(end && {end: format(end, FORMAT_DATETIME_NO_TZ)}),
            tagsIsControl: false,
        }).then(response => {
            return response.data;
        });
    },

    getCoachAlertSettings() {
        return APIUtils.get(
            `v1/users/me/prefs/apps?prefs=${COACH_COMMUNICATION_APP_ID}/1/*,${CUSTOM_ALERTS_APP_ID}/1/*`
        ).then(({data}) => {
            return formatAlertPreferences(data);
        });
    },

    updateCoachAlertSettings(values) {
        const appId = values.some(value => value.prefName === 'prefMeth')
            ? COACH_COMMUNICATION_APP_ID
            : CUSTOM_ALERTS_APP_ID;
        const preferenceData = values.map(value => ({
            appId,
            appInstId: '1',
            ...value,
        }));

        return APIUtils.post('v1/users/me/prefs/apps', preferenceData).then(
            ({data}) => {
                return formatAlertPreferences(data);
            }
        );
    },

    getContactsAlertSettings() {
        return APIUtils.get('v1/users/me/settings/alerts/bg').then(
            ({data}) => data
        );
    },

    updateContactsAlertSettings(values) {
        return APIUtils.patch('v1/users/me/settings/alerts/bg', values).then(
            ({data}) => data
        );
    },

    getOrderEligibility() {
        return APIUtils.get('v1/users/me/supplies/bgStrips/eligibility').then(
            ({data: {valid, errors}}) => {
                return valid
                    ? BloodSugarUtils.orderStatus(200)
                    : BloodSugarUtils.orderStatus(errors[0].code);
            }
        );
    },

    getStrips() {
        return APIUtils.get('v1/users/me/supplies/bgStrips').then(
            ({data: {remaining}}) => {
                return remaining;
            }
        );
    },

    getLabReportedA1C() {
        // This API uses a different base URL than the others in Member Portal
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-athome-labs/users/me/labOrders/results?testType=HbA1C`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${UserAPI.getCurrentAccessToken()}`,
                    'Content-Type': 'application/json',
                },
            }
        ).then(data => data.data || []);
    },

    getSelfReportedA1C() {
        return APIUtils.get('v1/users/me/info/health').then(
            data => find(data.data, ['id', 'a1c']) || ''
        );
    },

    orderStrips() {
        return APIUtils.post('v1/users/me/supplies/orders', {
            orderType: 'RK', // RK stands for refill kit
        }).then(({data}) => data);
    },

    updateStrips(value) {
        return APIUtils.put('v1/users/me/supplies/bgStrips/remaining', value, {
            headers: {
                'Content-Type': 'text/plain',
            },
        });
    },

    updateNote({id, data}) {
        return APIUtils.patch(
            `v1/users/me/readings/bgs/${id}`,
            {
                tags: data,
            },
            {
                headers: {
                    // 'charset=UTF-8' is required by the back-end to handle
                    // special characters such as a right single quote (’)
                    'Content-Type': 'application/json;charset=UTF-8',
                },
            }
        );
    },

    addReading({
        timestamp,
        value,
        carb,
        eatCode,
        feelingCode,
        note,
        short,
        long,
        premix,
    }) {
        return APIUtils.post('v1/users/me/readings/bgs/data', {
            timestamp,
            value: parseInt(value, 10),
            tags: {
                /* eslint-disable camelcase */
                source: 7,
                is_control: false,
                ...(note && {note}),
                ...(carb && {carb: parseInt(carb, 10)}),
                ...(eatCode && {eat_code: parseInt(eatCode, 10)}),
                ...(feelingCode && {feeling_code: parseInt(feelingCode, 10)}),
                ...((short || long || premix) && {
                    insulin: {
                        ...(short && {
                            short_float: roundFloat(short),
                        }),
                        ...(long && {long_float: roundFloat(long)}),
                        ...(premix && {
                            premix_float: roundFloat(premix),
                        }),
                    },
                }),
                /* eslint-enable camelcase */
            },
        });
    },
};

export default BloodSugarAPI;
