import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ExternalPageLink from '@teladoc/fe-ccm/ui/common/external-page-link/ExternalPageLink';
import config from '~/config';
import css from './Footer.scss';

const FooterPublic = () => {
    const {t} = useTranslation();
    const {userLocale} = useSelector(state => state.user);

    return (
        <footer className={css.root}>
            <div className={css.container}>
                <p className={css.company}>&copy; Teladoc Health, Inc</p>
                <div className={css.linksWrapper}>
                    <p className={css.links}>
                        <span className={css.link}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={config.TERMS_OF_SERVICE_URL[userLocale]}
                            >
                                {t('footer.terms')}
                            </a>
                            <ExternalPageLink className={css.externalLink} />
                        </span>
                        <span className={css.link}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={config.PRIVACY_POLICY_URL[userLocale]}
                            >
                                {t('footer.privacy')}
                            </a>
                            <ExternalPageLink className={css.externalLink} />
                        </span>
                        <span className={css.link}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={config.PRIVACY_PRACTICES_URL[userLocale]}
                            >
                                {t('footer.privacyPractices')}
                            </a>
                            <ExternalPageLink className={css.externalLink} />
                        </span>
                    </p>
                </div>
                <span className={css.version}>
                    {t('footer.version')} {process.env.VERSION}
                </span>
            </div>
        </footer>
    );
};

export default FooterPublic;
