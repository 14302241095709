import camelCase from 'lodash/camelCase';
import {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {cnb} from 'cnbuilder';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
// The below is installed as a dependency of fe-ui for use in Modal so we don't have to manage it in this project
// eslint-disable-next-line node/no-extraneous-import
import FocusTrap from 'focus-trap-react';
import IconArrowLeftDefault from '@teladoc/pulse/icons/arrow-left-default.svg';
import IconCloseDefault from '@teladoc/pulse/icons/close-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import Loader from '../loader/Loader';
import ApiError from '../common/api-error/ApiError';
import MixpanelUtils from '../common/utilities/mix-panel';
import css from './Chat.scss';
import {chatConnect, chatSetCurrentChannel} from './chat-actions';
import ChatUtils from './chat-utils';
import Channels from './channels/Channels';
import Messages from './messages/Messages';

const Chat = ({onClose}) => {
    const shimRef = useRef();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isConnected, isError, channels, currentChannel} = useSelector(
        state => state.chat
    );
    const {isOneApp} = useSelector(state => state.app);
    const [isShowing, setIsShowing] = useState(false);
    const onBackClick = () => {
        dispatch(chatSetCurrentChannel(null));
    };
    const onCloseClick = () => {
        setIsShowing(show => !show);

        document.querySelector('html')?.classList?.remove('Chat-open');

        onClose();
    };
    const onCloseIconClick = () => {
        if (isOneApp) {
            MixpanelUtils.track({
                event: 'messages.ccmcoaching.close.clicked',
                properties: {
                    button: 'x icon',
                },
            });
        }
        onCloseClick();
    };
    const onEscapePress = evt => {
        if (evt.key === 'Escape') {
            onCloseClick();
        }
    };
    const onCloseBGClick = evt => {
        if (isOneApp) {
            MixpanelUtils.track({
                event: 'messages.ccmcoaching.close.clicked',
                properties: {
                    button: 'background',
                },
            });
        }
        onCloseClick();
    };
    const onChannelSelected = channel => {
        dispatch(chatSetCurrentChannel(channel));
    };

    useEffect(() => {
        setIsShowing(true);

        document.querySelector('html')?.classList?.add('Chat-open');

        document.addEventListener('keydown', onEscapePress);

        return () => {
            document.querySelector('html')?.classList?.remove('Chat-open');

            document.removeEventListener('keydown', onEscapePress);

            if (!isOneApp) {
                onBackClick();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isConnected) {
            dispatch(chatConnect());
        }
    }, [dispatch, isConnected]);

    return (
        <div className={cnb(css.root, {[css.showing]: isShowing})}>
            <div
                ref={shimRef}
                className={css.shim}
                onClick={onCloseBGClick}
                role="none"
            />
            <FocusTrap focusTrapOptions={{clickOutsideDeactivates: true}}>
                <div className={css.container} role="dialog" aria-modal="true">
                    <div className={css.header}>
                        {currentChannel && !isOneApp && (
                            <Button
                                className={css.back}
                                aria-label={t('header.chat.back')}
                                onClick={onBackClick}
                            >
                                <IconArrowLeftDefault />
                            </Button>
                        )}
                        <h1 className={css.heading}>
                            {currentChannel
                                ? isOneApp
                                    ? currentChannel.customType ===
                                      ChatUtils.CHANNEL_TYPES.FOOD
                                        ? t('header.chat.foodLogFeedback')
                                        : t('header.chat.oneAppTitle')
                                    : t(
                                          `header.chat.channels.${camelCase(
                                              currentChannel.customType
                                          )}.title`
                                      )
                                : t('header.chat.title')}
                        </h1>
                        <Button
                            className={css.close}
                            aria-label={t('header.chat.close')}
                            onClick={onCloseIconClick}
                        >
                            <IconCloseDefault />
                        </Button>
                    </div>
                    <div className={css.content}>
                        {!isConnected && <Loader />}
                        {isError && <ApiError />}
                        {isConnected &&
                            (currentChannel ? (
                                <Messages
                                    channel={currentChannel}
                                    onBackToChannels={onBackClick}
                                />
                            ) : (
                                <Channels
                                    data={channels}
                                    onChannelSelected={onChannelSelected}
                                />
                            ))}
                    </div>
                </div>
            </FocusTrap>
        </div>
    );
};

Chat.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default Chat;
