import {Trans, useTranslation} from 'react-i18next';
import Image from '@teladoc/pulse/ui/Image';
import ScreenReader from '../common/screen-reader/ScreenReader';
import CommonUtils from '../common/utilities/common-utils';
import {getStore} from '../store';
import css from './Unavailable.scss';

const Unavailable = () => {
    const {t} = useTranslation();
    const store = getStore();
    const {
        app: {isTeamsEnvironment},
    } = store.getState();

    const getTranslationOrFallback = (key, fallback) => {
        const translation = t(key);
        const couldTranslate = translation !== key;
        const result = couldTranslate ? translation : fallback;

        return {result, couldTranslate};
    };
    const titleKey = 'errors.unavailable.title';
    const titleFallback = 'Uh-oh! Our site had a hiccup.';
    const {result: title, couldTranslate: couldTranslateTitle} =
        getTranslationOrFallback(titleKey, titleFallback);

    return (
        <div className={css.root} role="main">
            <Image
                classNameRoot={css.logo}
                cloudinaryImageId={
                    isTeamsEnvironment
                        ? 'registration/common/Teladoc_Logo_ES.svg'
                        : 'member-portal/common/logo_fjsmlz'
                }
                width={146}
                height={32}
                alt={t(isTeamsEnvironment ? 'oneappLogo' : 'logo')}
            />
            <Image
                classNameRoot={css.image}
                cloudinaryImageId="member-portal/common/error_tcmgli"
                width={164}
                height={117}
                alt=""
            />
            <h1 className="heading-sm">{title}</h1>
            {couldTranslateTitle && (
                <p>
                    <Trans
                        i18nKey="errors.unavailable.body"
                        components={[
                            /* eslint-disable react/jsx-key */
                            <a
                                href={`mailto:${process.env.MEMBER_SUPPORT_EMAIL}`}
                            >
                                <ScreenReader />
                            </a>,
                            <a href={`tel:${process.env.MEMBER_SUPPORT_PHONE}`}>
                                <ScreenReader />
                            </a>,
                            /* eslint-enable react/jsx-key */
                        ]}
                        values={{
                            email: process.env.MEMBER_SUPPORT_EMAIL,
                            number: CommonUtils.formatPhoneNumber(
                                process.env.MEMBER_SUPPORT_PHONE
                            ),
                        }}
                    />
                </p>
            )}
            {!couldTranslateTitle && (
                <p>
                    But good news ... we&apos;re working to get it back up and
                    running. In the meantime, try refreshing your browser. If it
                    happens again, email us at
                    <br />
                    <a href={`mailto:${process.env.MEMBER_SUPPORT_EMAIL}`}>
                        {process.env.MEMBER_SUPPORT_EMAIL}
                    </a>{' '}
                    or call
                    <br />
                    <a href={`tel:${process.env.MEMBER_SUPPORT_PHONE}`}>
                        {CommonUtils.formatPhoneNumber(
                            process.env.MEMBER_SUPPORT_PHONE
                        )}
                    </a>
                    .
                </p>
            )}
        </div>
    );
};

export default Unavailable;
