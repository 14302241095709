import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Trans} from 'react-i18next';
import Modal from '@teladoc/pulse/ui/Modal';
import ExternalPageLink from '@teladoc/fe-ccm/ui/common/external-page-link/ExternalPageLink';
import {useTransLoader} from '@teladoc/fe-i18n';
import {appUpdate} from '../../../app/app-actions';
import ModalImageTitle from '../../../common/modal-image-title/ModalImageTitle';
import Link from '../../../router/Link';
import {getPrimaryRouteById, getSecondaryRoute} from '../../../router/routes';
import {
    MEMBER_SUPPORT_URL,
    CONDITION_MANAGEMENT_HOMEPATH,
} from '../../../config';
import css from './ScaleConfirmationModal.scss';

const dashboard = getPrimaryRouteById('dashboard').path;

const ScaleConfirmationModal = ({isOpen, onRequestClose}) => {
    const {t} = useTransLoader('weight');
    const {push} = useHistory();
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const {userLocale, weightDevice} = useSelector(state => state.user);
    const {isOneApp} = useSelector(state => state.app);
    const {hasSafeContent} = useSelector(state => state.app);
    const onConfirmClick = () => {
        const destination = getSecondaryRoute({
            primaryId: 'weight',
            secondaryId: 'getStarted',
        }).path;

        if (destination !== pathname) {
            push(destination, {backToPath: pathname});
        }

        onRequestClose();

        if (isOneApp) {
            dispatch(
                appUpdate({
                    weightScaleConfirmation: false,
                })
            );
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            title={
                <ModalImageTitle
                    title={t('overview.modal.title')}
                    src={`member-portal/weight/overview/${weightDevice}`}
                />
            }
            onRequestClose={onRequestClose}
            primaryAction={{
                variant: 'primary',
                onClick: onConfirmClick,
                children: t('overview.modal.yes'),
            }}
            secondaryAction={{
                onClick: onRequestClose,
                children: t('overview.modal.notYet'),
            }}
        >
            <p className={css.info}>
                {t(
                    `overview.modal.paragraph1.${
                        hasSafeContent ? 'safeContent' : 'default'
                    }`
                )}
            </p>
            <p>
                <Trans
                    i18nKey={`weight:overview.modal.paragraph2.${
                        hasSafeContent ? 'safeContent' : 'default'
                    }`}
                    components={[
                        /* eslint-disable react/jsx-key */
                        <Link
                            to={
                                isOneApp
                                    ? CONDITION_MANAGEMENT_HOMEPATH
                                    : dashboard
                            }
                            onClick={onRequestClose}
                        />,
                        <a
                            href={MEMBER_SUPPORT_URL[userLocale]}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ExternalPageLink className={css.externalLink} />
                        </a>,
                        /* eslint-enable react/jsx-key */
                    ]}
                />
            </p>
        </Modal>
    );
};

ScaleConfirmationModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
};

export default ScaleConfirmationModal;
