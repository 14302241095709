import {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import NoMatch from '@teladoc/fe-ccm/ui/router/NoMatch';
import {IS_PROD} from '@teladoc/fe-ccm/ui/config';
import Loader from '@teladoc/fe-ccm/ui/loader/Loader';
import {useFeatureFlagContext} from '@livongo/utilities/system/featureFlag';
import RoutePrivate from '../router/RoutePrivate';
import {
    coreRoutes,
    commonRoutes,
    subNavRoutes,
    hiddenRoutes,
} from '../router/routes';
import PrivacyBanner from '../privacy/PrivacyBanner';
import css from './TeamsApp.scss';

const mainRoutes = [
    ...coreRoutes,
    ...subNavRoutes,
    ...commonRoutes,
    ...hiddenRoutes,
];

const relativeRoutes = mainRoutes.filter(route => route.isRelative);

const App = () => {
    const {updateFeatureFlag} = useFeatureFlagContext();

    // This window object is used to update FeatureFlag Toggle via console.
    if (!IS_PROD) {
        window.updateFeatureFlag = ({
            featureName,
            oneAppActive = false,
            legacyActive = false,
        }) => {
            return updateFeatureFlag({
                featureName,
                oneAppActive,
                legacyActive,
            });
        };
    }

    return (
        <Suspense fallback={<Loader />}>
            <div className={css.root}>
                <PrivacyBanner />
                <div className={css.content}>
                    <div role="main">
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                {relativeRoutes.map(
                                    ({
                                        id,
                                        path,
                                        programSlugs,
                                        component: Component,
                                    }) => {
                                        const props = {
                                            id,
                                            path,
                                            component: Component,
                                            ...(programSlugs && {
                                                programSlugs,
                                            }),
                                        };

                                        return (
                                            <RoutePrivate key={id} {...props} />
                                        );
                                    }
                                )}
                                <Route component={NoMatch} />
                            </Switch>
                        </Suspense>
                    </div>
                </div>
            </div>
        </Suspense>
    );
};

App.propTypes = {};

export default App;
