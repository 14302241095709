import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Redirect, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import Card from '../common/card/Card';
import {getPrimaryRouteById} from './routes';
import css from './NoMatch.scss';

const dashboard = getPrimaryRouteById('dashboard');
const login = getPrimaryRouteById('login');

const NoMatch = ({setShowSidebar, getCareID}) => {
    const {push} = useHistory();
    const {t} = useTranslation();
    const {isManuallyLoggedOut} = useSelector(state => state.user);
    const {isOneApp} = useSelector(state => state.app);
    const onClick = useCallback(
        evt => {
            if (isOneApp) {
                setShowSidebar();
                push(getCareID.path);
            } else {
                push(dashboard.path);
            }
        },
        [setShowSidebar, getCareID, isOneApp, push]
    );

    return (
        <div className={css.root}>
            <Card className={css.card} fullExtension>
                <Image
                    classNameRoot={css.image}
                    cloudinaryImageId="member-portal/common/error_tcmgli"
                    width={164}
                    height={117}
                    alt=""
                />
                <h1 className="heading-xs">{t('errors.missing.title')}</h1>
                <p>{t('errors.missing.body')}</p>
                <Button
                    className={css.button}
                    variant="primary"
                    onClick={onClick}
                >
                    {t('errors.missing.action')}
                </Button>
            </Card>
            {/* If a user logs out while on a URL that does not match a verified route,
            they are unable to be redirected to the login screen because
            this isn't a properly protected route so we redirect them manually */}
            {isManuallyLoggedOut && (
                <Redirect
                    to={{
                        pathname: login.path,
                    }}
                />
            )}
        </div>
    );
};

NoMatch.propTypes = {
    setShowSidebar: PropTypes.func,
    getCareID: PropTypes.object,
};

export default NoMatch;
