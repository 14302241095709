import isNil from 'lodash/isNil';
import format from 'date-fns/format';
import APIUtils from '@livongo/utilities/system/api';
import {FORMAT_DATETIME_NO_TZ} from '../config';

const toUTC = string => {
    const date = new Date(string);

    return date.getTime() + date.getTimezoneOffset() * 60 * 1000;
};

const WeightAPI = {
    getReadings({start, end, count, isIrregular = null, isUTC = false}) {
        return APIUtils.get('v1/users/me/reading/weights', {
            ...(start && {
                start: format(
                    isUTC ? toUTC(start) : new Date(start),
                    FORMAT_DATETIME_NO_TZ
                ),
            }),
            ...(end && {
                end: format(
                    isUTC ? toUTC(end) : new Date(end),
                    FORMAT_DATETIME_NO_TZ
                ),
            }),
            ...(count && {count}),
            ...(!isNil(isIrregular) && {isIrregular}),
        }).then(response => response.data.weightReadings);
    },

    getStatistics({durations, extended = true}) {
        return APIUtils.get('v1/users/me/reading/weight/stats', {
            ...(durations && {durations}),
            ...(extended && {extended_stats: extended}), // eslint-disable-line camelcase
        }).then(({data}) => data);
    },

    getTarget() {
        // Different from other endpoints, the catch statement below handles
        // a 404 status returned when a goal weight doesn't exist yet
        return APIUtils.get('/v1/users/me/reading/weight/target')
            .then(({data}) => data)
            .catch(error => {
                return null;
            });
    },

    updateTarget(data) {
        return APIUtils.post('/v1/users/me/reading/weight/target', data);
    },

    updateRegularity({id, isIrregular}) {
        return APIUtils.put(`v1/users/me/reading/weight/${id}`, {
            isIrregular,
        });
    },
};

export default WeightAPI;
