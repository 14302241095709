import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import {useRef} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {cnb} from 'cnbuilder';
import {useTranslation} from 'react-i18next';
import TextTruncate from 'react-text-truncate';
import getYear from 'date-fns/getYear';
import differenceInDays from 'date-fns/differenceInDays';
import ExternalPageLink from '@teladoc/fe-ccm/ui/common/external-page-link/ExternalPageLink';
import IconArrowRightDefault from '@teladoc/pulse/icons/arrow-right-default.svg';
import IconHelpDefault from '@teladoc/pulse/icons/help-default.svg';
import IconFoodDefault from '@teladoc/pulse/icons/food-default.svg';
import IconGroupDefault from '@teladoc/pulse/icons/group-default.svg';
import {useL10nDateTimeContext} from '@teladoc/pulse/ui/Context/l10n/DateTime';
import {MEMBER_SUPPORT_URL} from '../../config';
import MixpanelUtils from '../../common/utilities/mix-panel';
import ChatUtils from '../chat-utils';
import css from './ChatCard.scss';

const TODAY = new Date();
const CURRENT_YEAR = getYear(TODAY);
const {
    CHANNEL_TYPES: {COACHING, FOOD, SUPPORT},
    USER_TYPES: {COACH, MEMBER},
    MESSAGE_TYPES: {ADMIN},
    getUsersInChannel,
} = ChatUtils;
const CHANNELS = {
    [COACHING]: {
        icon: IconGroupDefault,
        mixpanelEvent: 'chat.coachingscreen.one_on_one.clicked',
    },
    [FOOD]: {
        icon: IconFoodDefault,
        mixpanelEvent: 'chat.selection.food_logging.clicked',
    },
    [SUPPORT]: {
        icon: IconHelpDefault,
        mixpanelEvent: 'chat.coachingscreen.support.clicked',
    },
};

const Channel = ({data, onSelected}) => {
    const {intlFormat} = useL10nDateTimeContext();
    const {t} = useTranslation();
    const {fullName, userLocale} = useSelector(state => state.user);
    const {customType, url, unreadMessageCount, lastMessage} = data;
    const userType = data?.sender?.metaData?.userType;
    const isOneMember = useRef(
        customType !== SUPPORT &&
            getUsersInChannel({channel: data}).length === 1
    );
    const hasLastMessage = !isEmpty(lastMessage);
    const lastMessageDate = hasLastMessage
        ? new Date(lastMessage.createdAt)
        : null;
    const i18nKey = `header.chat.channels.${camelCase(customType)}`;
    const Icon = CHANNELS[customType].icon;
    const onClick = evt => {
        // if this is the coaching channel but the first time a user clicks it, fire a different event
        // otherwise use the events defined in the CHANNELS const above, even for coaching channel
        MixpanelUtils.track({
            event:
                url === ChatUtils.NEW_CHANNEL_URL
                    ? 'chat.coachingscreen.chat.clicked'
                    : CHANNELS[customType].mixpanelEvent,
        });

        if (customType !== SUPPORT) {
            return onSelected(data);
        }

        // member support channel is not a real channel rather a static link
        window.open(MEMBER_SUPPORT_URL[userLocale]);
    };
    const onKeyPress = evt => {
        const {which, keyCode} = evt;
        const key = which ? which : keyCode;

        // if key pressed is enter or space bar
        if (key === 13 || key === 32) {
            evt.preventDefault();

            onClick();
        }
    };
    const {messageType, message} = lastMessage || {};
    let senderName =
        userType === MEMBER ? fullName : ChatUtils.getSenderName(lastMessage);

    if (userType === COACH) {
        senderName = ChatUtils.formatUserName(senderName).abbreviated;
    }

    return (
        <div
            className={cnb(css.root, css.channel)}
            role="button"
            tabIndex={0}
            onKeyPress={onKeyPress}
            onClick={onClick}
        >
            <div className={css.iconContainer}>
                <Icon className={css.icon} />
            </div>
            <div className={css.content}>
                <span className={css.contentHeader}>
                    <h2 className="heading-xs">{t(`${i18nKey}.title`)} </h2>
                    {customType === SUPPORT && (
                        <ExternalPageLink className={css.externalLink} />
                    )}
                </span>
                {(!isOneMember.current ||
                    (customType !== FOOD && !hasLastMessage)) && (
                    <p>{t(`${i18nKey}.description`)}</p>
                )}
                {isOneMember.current && hasLastMessage && (
                    <div aria-hidden="true">
                        <div className={css.lastMessageMeta}>
                            <div className={css.nameContainer}>
                                <p>{senderName}</p>
                            </div>
                            <span className={css.date}>
                                {intlFormat(
                                    lastMessageDate,
                                    getYear(lastMessageDate) === CURRENT_YEAR
                                        ? differenceInDays(
                                              TODAY,
                                              lastMessageDate
                                          ) === 0
                                            ? {
                                                  hour: 'numeric',
                                                  minute: 'numeric',
                                              }
                                            : {month: 'short', day: 'numeric'}
                                        : {
                                              month: 'numeric',
                                              day: 'numeric',
                                              year: '2-digit',
                                          }
                                )}
                            </span>
                            <div>
                                {unreadMessageCount > 0 && (
                                    <span className={css.unread}>
                                        {unreadMessageCount > 9
                                            ? '9+'
                                            : unreadMessageCount}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className={css.lastMessage}>
                            <TextTruncate
                                line={2}
                                truncateText="..."
                                text={
                                    messageType === ADMIN
                                        ? message
                                              .replaceAll(/<p>/g, '')
                                              .replaceAll(/<\/p>/g, ' ')
                                        : lastMessage.message
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
            {isOneMember.current && unreadMessageCount === 0 && (
                <IconArrowRightDefault className={css.arrow} />
            )}
        </div>
    );
};

Channel.propTypes = {
    data: PropTypes.object.isRequired,
    onSelected: PropTypes.func,
};

Channel.defaultProps = {
    data: {customType: SUPPORT, unreadMessageCount: 0},
};

export default Channel;
