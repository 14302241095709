import {ActionType} from 'redux-promise-middleware';
import {USER_LOGOUT} from '../user/user-actions';
import {
    CHAT_CONNECT,
    CHAT_TOGGLE,
    CHAT_LOAD_MESSAGES,
    CHAT_UPDATE_MESSAGES,
    CHAT_UPDATE_UNREAD_COUNT,
    CHAT_MARK_CHANNEL_READ,
    CHAT_SET_CURRENT_CHANNEL,
    CHAT_SET_WAITING_FOR_NEW_COACH,
} from './chat-actions';
import ChatUtils from './chat-utils';

export const initialState = {
    isLoading: false,
    isError: false,
    isConnected: false,
    isShowing: false,
    channels: [],
    channelMessages: {},
    currentChannel: null,
    isWaitingForNewCoach: false,
    hasCoachRepliedActiveSession: false,
    // used to uniquely identify a stamp that can be tested against when new messages are received from the server
    messagesKey: Date.now().toString(),
    hasUnread: {
        chronicCare: false,
        telemed: false,
        myStrength: false,
        foodLogger: false,
    },
};

export default function chat(state = initialState, {type, payload}) {
    switch (type) {
        case `${CHAT_CONNECT}_${ActionType.Pending}`: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case `${CHAT_CONNECT}_${ActionType.Fulfilled}`: {
            return {
                ...state,
                ...payload,
                isLoading: false,
                isConnected: true,
            };
        }

        case `${CHAT_CONNECT}_${ActionType.Rejected}`: {
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        }

        case CHAT_TOGGLE: {
            return {
                ...state,
                isShowing: !state.isShowing,
            };
        }

        case CHAT_SET_CURRENT_CHANNEL: {
            return {
                ...state,
                currentChannel: payload,
            };
        }

        case `${CHAT_LOAD_MESSAGES}_${ActionType.Pending}`: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case `${CHAT_LOAD_MESSAGES}_${ActionType.Rejected}`: {
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        }

        case `${CHAT_LOAD_MESSAGES}_${ActionType.Fulfilled}`: {
            // data contains `messages` and `query`
            const {
                channel: {name},
                data,
            } = payload;
            const newChannelMessages = {...state.channelMessages};

            newChannelMessages[name] = data;

            return {
                ...state,
                isLoading: false,
                channelMessages: newChannelMessages,
            };
        }

        case CHAT_UPDATE_UNREAD_COUNT: {
            return {...state, ...payload};
        }

        case CHAT_UPDATE_MESSAGES: {
            const {channel, message, isFirstInteraction} = payload;
            const {channels, currentChannel, channelMessages} = state;
            const newChannelMessages = {...channelMessages};
            const channelData = newChannelMessages[channel.name];
            const isFromCoach =
                message?.sender?.metaData?.user_type ===
                ChatUtils.USER_TYPES.COACH;
            const isViewingCurrentChannel =
                currentChannel && currentChannel.name === channel.name;
            const hasUnreadsInOtherChannels =
                ChatUtils.getUnreadsInOtherChannels(channel.name, channels);
            const hasCCMUnread =
                (isFromCoach && !isViewingCurrentChannel) ||
                (!isFromCoach && hasUnreadsInOtherChannels);

            // if the channel messages exist, update them. they can potentially not exist if this is the first load
            // and user has not gone into the Chat yet to load their messages but a coach sent them a message during
            // that time frame
            if (channelData) {
                channelData.messages.unshift(message);
            } else if (isFirstInteraction) {
                const query = channel.createPreviousMessageListQuery();

                query.hasMore = false;

                newChannelMessages[channel.name] = {
                    query,
                    messages: [message],
                };
            }

            if (isViewingCurrentChannel) {
                // mark the channel read if its the same as current channel
                channel.markAsRead();
            }

            return {
                ...state,
                channelMessages: newChannelMessages,
                hasUnread: {
                    ...state.hasUnread,
                    chronicCare: hasCCMUnread,
                },
                ...(isFirstInteraction
                    ? {
                          channels: [
                              // add the newly created channel and filter out the fake "new" channel
                              // this ensures if food log channel was created first it still stays in the channel list
                              channel,
                              ...state.channels.filter(
                                  item => item.url !== ChatUtils.NEW_CHANNEL_URL
                              ),
                          ],
                          currentChannel: channel,
                      }
                    : {
                          messagesKey: Date.now().toString(),
                      }),
                ...(isFromCoach && {
                    isWaitingForNewCoach: false,
                    hasCoachRepliedActiveSession: true,
                }),
            };
        }

        case CHAT_MARK_CHANNEL_READ: {
            const {name} = payload;
            const newChannels = [...state.channels];
            const currentChannel = newChannels.find(item => item.name === name);
            const hasCCMUnread = ChatUtils.getUnreadsInOtherChannels(
                name,
                newChannels
            );

            currentChannel.markAsRead();

            return {
                ...state,
                channels: ChatUtils.orderChannels(newChannels),
                hasUnread: {
                    ...state.hasUnread,
                    chronicCare: hasCCMUnread,
                },
            };
        }

        case CHAT_SET_WAITING_FOR_NEW_COACH: {
            return {
                ...state,
                isWaitingForNewCoach: payload,
            };
        }

        case `${USER_LOGOUT}_${ActionType.Fulfilled}`: {
            return initialState;
        }

        default:
            return state;
    }
}
