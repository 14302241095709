import {cnb} from 'cnbuilder';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import ExternalPageLink from '@teladoc/fe-ccm/ui/common/external-page-link/ExternalPageLink';
import IconHelpDefault from '@teladoc/pulse/icons/help-default.svg';
import IconGroupDefault from '@teladoc/pulse/icons/group-default.svg';
import IconMapsignsDefault from '@teladoc/pulse/icons/map-signs-default.svg';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import {getPrimaryRouteById} from '@teladoc/fe-ccm/ui/router/routes';
import config from '~/config';
import css from './HelpDropdown.scss';

const coaching = getPrimaryRouteById('coaching');

function getLinks({activePrograms, hasOnlyBehavioralHealth}) {
    return [
        {
            id: 'support',
            icon: IconHelpDefault,
        },
        ...(!isEmpty(activePrograms) && !hasOnlyBehavioralHealth
            ? [
                  {
                      id: 'coaching',
                      icon: IconGroupDefault,
                  },
              ]
            : []),
        {
            id: 'tour',
            icon: IconMapsignsDefault,
        },
    ];
}

const HelpDropdown = ({className, onItemClick, isReadOnly}) => {
    const {t} = useTranslation();
    const {push} = useHistory();
    const {pathname} = useLocation();
    const {activePrograms, userLocale} = useSelector(state => state.user);
    const {behavioralHealth: hasBehavioralHealth} = activePrograms;
    const hasOnlyBehavioralHealth =
        hasBehavioralHealth && size(activePrograms) === 1;
    const navItemClasses = cnb(css.navItem, {
        [css.navItemReadOnly]: isReadOnly,
    });
    const onHelpActivate = id => {
        if (onItemClick) {
            onItemClick(id);
        } else {
            return;
        }

        switch (id) {
            case 'support':
                MixpanelUtils.track({
                    event: 'help.header.memberhelpcenter.clicked',
                });

                window.open(config.MEMBER_SUPPORT_URL[userLocale]);

                break;
            case 'coaching':
                MixpanelUtils.track({event: 'help.header.coaching.clicked'});

                push(coaching.path, {backToPath: pathname});

                break;
            case 'tour':
                MixpanelUtils.track({event: 'help.header.producttour.clicked'});

                break;
        }
    };

    return (
        <ul className={cnb(css.root, className)}>
            {getLinks({
                activePrograms,
                hasOnlyBehavioralHealth,
            }).map(({id, icon: Icon}) => {
                return (
                    <li key={id}>
                        <a
                            role="button"
                            href="#helpCenter"
                            data-id={id}
                            className={cnb(
                                navItemClasses,
                                css['navItem-support']
                            )}
                            onClick={() => onHelpActivate(id)}
                            onKeyPress={evt =>
                                evt.key === 'Enter' ? onHelpActivate(evt) : null
                            }
                            tabIndex="0" // needed for FocusTrap
                        >
                            <Icon className={css.navItemIcon} />
                            {t(`header.help.${id}`)}
                            {id === 'support' && <ExternalPageLink />}
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};

HelpDropdown.propTypes = {
    className: PropTypes.string,
    onItemClick: PropTypes.func,
    isReadOnly: PropTypes.bool,
};

export default HelpDropdown;
