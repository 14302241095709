import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Redirect, Route, useLocation} from 'react-router-dom';
import useTitle from '../common/use-title';
import UserUtils from '../user/user-utils';
import {getPrimaryRouteById} from './routes';

const dashboard = getPrimaryRouteById('dashboard');
const profile = getPrimaryRouteById('profilePreferences');
const login = getPrimaryRouteById('login');

const RoutePrivate = ({id, programSlugs, component: Component, ...rest}) => {
    const location = useLocation();
    const {path, parentPath, isOneApp} = rest;
    const {isAuthenticated, isManuallyLoggedOut} = useSelector(
        state => state.user
    );
    const willRender = useMemo(
        () => UserUtils.willRender(programSlugs),
        [programSlugs]
    );

    useTitle({
        id,
        path,
        ...(parentPath && {parentPath}),
    });

    return (
        <Route id={id} {...rest}>
            {isAuthenticated && willRender ? (
                <Component id={id} {...rest} />
            ) : isOneApp ? (
                // TODO: temporary fix to redirect user to signin page.
                ''
            ) : (
                <Redirect
                    to={{
                        pathname: isAuthenticated ? profile.path : login.path,
                        state: {
                            // check if user logged out manually in which case we should redirect the next login attempt to dashboard
                            from: isManuallyLoggedOut
                                ? dashboard.path
                                : location,
                        },
                    }}
                />
            )}
        </Route>
    );
};

RoutePrivate.propTypes = {
    id: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
        PropTypes.object,
    ]),
    programSlugs: PropTypes.array,
};

export default RoutePrivate;
