import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import Modal from '@teladoc/pulse/ui/Modal';
import ModalImageTitle from '../modal-image-title/ModalImageTitle';
import {userLogout} from '../../user/user-actions';
import css from './SessionExpiredModal.scss';

let sessionTimeout;

const SessionExpiredModal = ({
    isOpen,
    onStay,
    oneAppLogout,
    setShowIdleModal,
}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [timeLeft, setTimeLeft] = useState(60); // 60 seconds
    const {programs} = useSelector(state => state.user);
    const onLogout = () => {
        dispatch(userLogout({oneAppLogout, enrolledPrograms: programs}));
    };

    useEffect(() => {
        if (timeLeft > 0) {
            sessionTimeout = setTimeout(() => {
                const time = timeLeft - 1;

                setTimeLeft(time);

                if (time <= 0) {
                    clearTimeout(sessionTimeout);
                    dispatch(
                        userLogout({oneAppLogout, enrolledPrograms: programs})
                    );

                    if (setShowIdleModal) {
                        setShowIdleModal(false);
                    }
                }
            }, 1000);
        }

        return () => {
            clearTimeout(sessionTimeout);
        };
    }, [dispatch, timeLeft, oneAppLogout, programs, setShowIdleModal]);

    return (
        <Modal
            isOpen={isOpen}
            title={
                <ModalImageTitle
                    title={t('sessionExpired.title')}
                    src="member-portal/common/clock_qyvxtg"
                />
            }
            onRequestClose={onStay}
            primaryAction={{
                variant: 'primary',
                onClick: onStay,
                children: t('sessionExpired.stay'),
            }}
            secondaryAction={{
                onClick: onLogout,
                children: t('sessionExpired.leave'),
            }}
        >
            <p>{t('sessionExpired.description')}</p>
            <p className={css.seconds}>
                <Trans
                    i18nKey="app:sessionExpired.time"
                    values={{
                        count: timeLeft,
                    }}
                />
            </p>
        </Modal>
    );
};

SessionExpiredModal.propTypes = {
    isOpen: PropTypes.bool,
    onStay: PropTypes.func.isRequired,
    setShowIdleModal: PropTypes.func,
    oneAppLogout: PropTypes.func,
};

export default SessionExpiredModal;
