import {cnb} from 'cnbuilder';
import PropTypes from 'prop-types';
import css from './Sidebar.scss';
import Navigation from './navigation/Navigation';

const Sidebar = ({isOpen, isSubnavShowing, onNavItemClick}) => {
    return (
        <div className={cnb(css.root, {[css.showing]: isOpen})}>
            <Navigation
                isSubnavShowing={isSubnavShowing}
                onItemClick={onNavItemClick}
            />
        </div>
    );
};

Sidebar.propTypes = {
    isOpen: PropTypes.bool,
    isSubnavShowing: PropTypes.bool,
    onNavItemClick: PropTypes.func.isRequired,
};

export default Sidebar;
