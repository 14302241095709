import {cnb} from 'cnbuilder';
import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useTransLoader} from '@teladoc/fe-i18n';
import Image from '@teladoc/pulse/ui/Image';
import Button from '@teladoc/pulse/ui/Button';
import IconProfileDefault from '@teladoc/pulse/icons/profile-default.svg';
import IconDeviceDefault from '@teladoc/pulse/icons/device-default.svg';
import {getPrimaryRouteById} from '@teladoc/fe-ccm/ui/router/routes';
import MixpanelUtils from '../../../common/utilities/mix-panel';
import TransitionHeader from './Header/TransitionHeader';
import TransitionFooter from './Footer/TransitionFooter';
import css from './TransitionScreen.scss';

const TransitionScreen = mfaToken => {
    const {t} = useTransLoader('user');
    const {push} = useHistory();
    const location = useLocation();

    useEffect(() => {
        MixpanelUtils.track({event: 'preMFA.transitionscreen.viewed'});
    }, []);

    const onSubmitClick = () => {
        MixpanelUtils.track({event: 'connectmyaccounts.button.clicked'});

        // Extract the query string from the current URL
        const queryParams = location.search;

        // Combine the path and query parameters
        const newPathWithQuery = `${
            getPrimaryRouteById('mfa').path
        }${queryParams}`;

        // Push to the new path with query parameters and mfaToken as a string
        push(newPathWithQuery, mfaToken);
    };

    return (
        <div className={css.container}>
            <TransitionHeader />

            <div className={css.wrapper}>
                <h1 className={cnb(css.cardHeading, css.heading)}>
                    {t('transitionScreen.heading')}
                </h1>
                <p className={css.info}>{t('transitionScreen.description')}</p>
                <div className={css.listItemWithIcon}>
                    <div>
                        <IconProfileDefault className={css.icon} />
                    </div>
                    <div className={css.listItemBody}>
                        <span className={css.listItemSubheading}>
                            {t('transitionScreen.content1Subheading')}
                        </span>
                        {t('transitionScreen.content1Paragrapgh')}
                    </div>
                </div>
                <div className={css.listItemWithIcon}>
                    <div>
                        <IconDeviceDefault className={css.icon} />
                    </div>
                    <div className={css.listItemBody}>
                        <span className={css.listItemSubheading}>
                            {t('transitionScreen.content2Subheading')}
                        </span>
                        {t('transitionScreen.content2Paragrapgh')}
                    </div>
                </div>

                <Button
                    block
                    variant="primary"
                    role="link"
                    className={cnb(css.continue, css.alignButton)}
                    onClick={onSubmitClick}
                    iconPosition="left"
                    icon={
                        <Image
                            classNameRoot={css.img}
                            cloudinaryImageId="member-portal/common/Livongo_Icon.svg"
                            width={15}
                            height={20}
                            alt=""
                        />
                    }
                >
                    {t('transitionScreen.connectMyAccounts')}
                </Button>
            </div>
            <TransitionFooter />
        </div>
    );
};

export default TransitionScreen;
