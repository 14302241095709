import isArray from 'lodash/isArray';
import {useEffect, useRef, useState} from 'react';
import APIUtils from '@livongo/utilities/system/api';

export default function useApi(
    {api, params = {}, onLoad = null, onError = null},
    dependencies = []
) {
    const [isLoading, setIsLoading] = useState(true);
    const dataLoader = useRef(null);
    const data = useRef(null);
    const error = useRef(null);
    const apiCalls = [];

    useEffect(() => {
        let isCanceled = false;

        async function fetchData() {
            try {
                if (isArray(api)) {
                    if (params && !isArray(params)) {
                        throw new Error(
                            'The "params" must be an array if an array of APIs is passed.'
                        );
                    }

                    api.forEach((item, i) => {
                        apiCalls.push(
                            APIUtils.makeCancelable(api[i](params[i]))
                        );
                    });

                    data.current = await Promise.all(
                        apiCalls.map(item => item.promise)
                    );
                } else {
                    dataLoader.current = APIUtils.makeCancelable(api(params));
                    data.current = await dataLoader.current.promise;
                }

                if (onLoad) {
                    onLoad(data.current);
                }
            } catch (apiError) {
                error.current = apiError;

                if (onError) {
                    onError(apiError);
                }
            }

            if (!isCanceled) {
                setIsLoading(false);

                // eslint-disable-next-line require-atomic-updates
                dataLoader.current = null;
            }
        }

        fetchData();

        return () => {
            if (dataLoader.current) {
                dataLoader.current.cancel();
            }

            if (apiCalls.length) {
                apiCalls.forEach(item => {
                    item.cancel();
                });
            }

            dataLoader.current = null;
            isCanceled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return {
        isLoading,
        data: data.current,
        error: error.current,
    };
}
