import PropTypes from 'prop-types';
import {cnb} from 'cnbuilder';
import {useTranslation} from 'react-i18next';
import IconAlertDefault from '@teladoc/pulse/icons/alert-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import css from './ChatCard.scss';

const MemberError = ({onActionClick}) => {
    const {t} = useTranslation();
    const classes = cnb(css.root, css.memberError);
    const i18nKey = 'header.chat.messages.memberError';

    return (
        <div className={classes}>
            <div className={css.iconContainer}>
                <IconAlertDefault className={css.icon} />
            </div>
            <div className={css.content}>
                <h2 className="heading-xs">{t(`${i18nKey}.title`)}</h2>
                <p>{t(`${i18nKey}.description`)}</p>
                <Button onClick={onActionClick}>
                    {t(`${i18nKey}.action`)}
                </Button>
            </div>
        </div>
    );
};

MemberError.propTypes = {
    onActionClick: PropTypes.func.isRequired,
};

export default MemberError;
