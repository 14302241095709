import format from 'date-fns/format';
import APIUtils from '@livongo/utilities/system/api';
import ProtoUtils from '../common/utilities/proto-utils';
import {FORMAT_DATETIME_NO_TZ} from '../config';

const {
    getHeaders,
    Biometric: {
        AlertFlowClient,
        GetAlertStageRequest,
        UpdateAlertStageRequest,
        QuestionAnswer,
    },
} = ProtoUtils;

const BloodPressureAPI = {
    getReadings({userId, start, end, count}) {
        return APIUtils.get(`v1/users/${userId}/reading/bp`, {
            ...(start && {start: format(start, FORMAT_DATETIME_NO_TZ)}),
            ...(end && {end: format(end, FORMAT_DATETIME_NO_TZ)}),
            ...(count && {count}),
        }).then(response => response.data.bpReadings);
    },

    getStatistics({start, end} = {}) {
        return APIUtils.get('v1/users/me/reading/bp/stats', {
            ...(start && {start: format(start, FORMAT_DATETIME_NO_TZ)}),
            ...(end && {end: format(end, FORMAT_DATETIME_NO_TZ)}),
        }).then(response => response.data);
    },

    getAlert() {
        const request = new GetAlertStageRequest();

        request.setName('user/liv/me/alertStage');

        return AlertFlowClient.getAlertStage(request, getHeaders());
    },

    updateAlert(question, answer) {
        const request = new UpdateAlertStageRequest();
        const questionData = new QuestionAnswer();

        request.setName('user/liv/me/alertStage');
        questionData.setQuestionCode(question);
        questionData.setAnswerCode(answer);
        request.setQuestionAnswer(questionData);

        return AlertFlowClient.updateAlertStage(request, getHeaders());
    },
};

export default BloodPressureAPI;
