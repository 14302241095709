export const DEVICE_CATEGORIES = {
    WEIGHT_SCALE: 'weight_scale',
    BLOOD_SUGAR_METER: 'bg_meter',
    BLOOD_PRESSURE_MONITOR: 'bp_monitor',
};

const {WEIGHT_SCALE, BLOOD_SUGAR_METER, BLOOD_PRESSURE_MONITOR} =
    DEVICE_CATEGORIES;

export default {
    [BLOOD_SUGAR_METER]: {
        mixpanelToggleEvent: 'bg.meter.cta.clicked',
        devices: [
            {
                id: 'bg300',
                image: 'member-portal/user-guides/bg300_prkeqh',
                manuals: [
                    {
                        id: 'userGuide',
                        en: 'Livongo-for-Diabetes-(BG300)-User-Guide-EN.pdf',
                        es: 'Livongo-for-Diabetes-(BG300)-User-Guide-ES.pdf',
                    },
                    {
                        id: 'ownersManual',
                        en: 'PL00497-G-G2.4-Owners-Manual-English.pdf',
                        es: 'PL00862_Rev._A_G2.4_Owner_s_Manual_Spanish.pdf',
                    },
                    {
                        id: 'instructions',
                        en: 'Livongo-Blood-Glucose-Monitoring-System-(BG300)-Instructions-for-Use-EN.pdf',
                        es: 'Livongo-Blood-Glucose-Monitoring-System-(BG300)-Instructions-for-Use-ES.pdf',
                    },
                ],
            },
            {
                id: 'inTouch',
                image: 'member-portal/user-guides/in-touch_wwxw7s',
                manuals: [
                    {
                        id: 'about',
                        en: 'About-In-Touch-EN.pdf',
                        es: 'About-In-Touch-ES.pdf',
                    },
                    {
                        id: 'ownersManual',
                        en: 'In-Touch-Owners-Manual-EN.pdf',
                        es: 'In-Touch-Owners-Manual-ES.pdf',
                    },
                    {
                        id: 'aboutSoftware',
                        en: 'About-In-Touch-version-1.2.0.2111-or-1.2.0.1928-EN.pdf',
                        es: null,
                    },
                    {
                        id: 'ownersManualSoftware',
                        en: 'In-Touch-Owners-Manual-version-1.2.0.2111-or-1.2.0.1928-EN.pdf',
                        es: null,
                    },
                ],
            },
        ],
    },
    [BLOOD_PRESSURE_MONITOR]: {
        mixpanelToggleEvent: 'bp.meter.cta.clicked',
        devices: [
            {
                id: 'htn',
                image: 'member-portal/user-guides/htn_i1p9yv',
                manuals: [
                    {
                        id: 'quickStartGuide',
                        en: 'Livongo-Connected-Blood-Pressure-Monitor-UA-651BLE-LIV-Quick-Start-Guide-EN.pdf',
                        es: 'Livongo-Connected-Blood-Pressure-Monitor-UA-651BLE-LIV-Quick-Start-Guide-ES.pdf',
                    },
                ],
            },
            {
                id: 'ht900',
                image: 'member-portal/user-guides/ht900_ym7zbr',
                manuals: [
                    {
                        id: 'userGuide',
                        en: 'Livongo-HT900-User-Guide-EN.pdf',
                        es: 'Livongo-for-Hypertension-HT900-User-Guide-ES.pdf',
                    },
                ],
            },
            {
                id: 'ht945',
                image: 'member-portal/user-guides/HT945_4x_l0iwsn',
                manuals: [
                    {
                        id: 'userGuide',
                        en: 'Teladoc-HT945-User-Guide-EN.pdf',
                        es: 'Teladoc-HT945-User-Guide-ES.pdf',
                    },
                ],
            },
        ],
    },
    [WEIGHT_SCALE]: {
        mixpanelToggleEvent: 'weight.monitor.cta.clicked',
        devices: [
            {
                id: 'wm1000',
                image: 'member-portal/user-guides/wm1000_gpcbrg',
                manuals: [
                    {
                        id: 'userGuide',
                        en: 'Livongo-Scale-WM1000-User-Guide-EN.pdf',
                        es: 'Livongo-Scale-WM1000-User-Guide-ES.pdf',
                    },
                ],
            },
            {
                id: 'wm1500',
                image: 'member-portal/user-guides/wm1500_k4vldz',
                manuals: [
                    {
                        id: 'userGuide',
                        en: 'Livongo-Scale-WM1500-User-Guide-EN.pdf',
                        es: 'Livongo-Scale-WM1500-User-Guide-ES.pdf',
                    },
                ],
            },
        ],
    },
};
