import PropTypes from 'prop-types';
import {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import IconHelpDefault from '@teladoc/pulse/icons/help-default.svg';
import Modal from '@teladoc/pulse/ui/Modal';
import Tour from '@teladoc/fe-ccm/ui/common/tour/Tour';
import ModalImageTitle from '@teladoc/fe-ccm/ui/common/modal-image-title/ModalImageTitle';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import cssHeader from '~/header/HeaderPrivate.scss';
import cssNav from '~/sidebar/navigation/Navigation.scss';
import HelpDropdown from '../help-dropdown/HelpDropdown';
import css from './MainOnboarding.scss';

function getSteps({t, isNavShowing, hasChatAvailable}) {
    const steps = [];

    if (isNavShowing) {
        steps.push({
            target: `.${cssNav.mainNav}`,
            title: t('onboarding.main.step1.title'),
            description: t('onboarding.main.step1.description'),
            placement: 'right',
            disableBeacon: true,
        });
    }

    steps.push({
        target: `.${cssHeader.help}`,
        title: t('onboarding.main.step2.title'),
        description: t('onboarding.main.step2.description'),
        disableBeacon: true,
    });

    if (hasChatAvailable) {
        steps.push({
            target: `.${cssHeader.chat}`,
            title: t('onboarding.main.step3.title'),
            description: t('onboarding.main.step3.description'),
            disableBeacon: true,
        });
    }

    steps.push({
        target: `.${cssHeader.member}`,
        title: t('onboarding.main.step4.title'),
        description: t('onboarding.main.step4.description'),
        disableBeacon: true,
    });

    return steps;
}

const MainOnboarding = ({isNavShowing, onHidden}) => {
    const {t} = useTranslation();
    const {firstName} = useSelector(state => state.user);
    const {hasChatAvailable} = useSelector(state => state.app);
    const [showModal, setShowModal] = useState(true);
    const [showTour, setShowTour] = useState(false);
    const onModalClose = () => {
        MixpanelUtils.track({event: 'firsttour.dashboard.nothanks.clicked'});

        onHidden();
    };
    const onTourStart = () => {
        setShowTour(true);
        setShowModal(false);

        MixpanelUtils.track({event: 'firsttour.dashboard.takethetour.clicked'});
    };

    return (
        <>
            <Modal
                isOpen={showModal}
                title={
                    <ModalImageTitle
                        title={t('onboarding.main.productIntro.title')}
                        src="member-portal/common/info_vshiwq"
                    />
                }
                onRequestClose={onModalClose}
                primaryAction={{
                    variant: 'primary',
                    onClick: onTourStart,
                    children: t('onboarding.main.productIntro.confirm'),
                }}
                secondaryAction={{
                    onClick: onHidden,
                    variant: 'secondary',
                    children: t('onboarding.main.productIntro.cancel'),
                }}
            >
                <p>
                    <Trans
                        i18nKey="app:onboarding.main.productIntro.description"
                        components={[
                            // eslint-disable-next-line react/jsx-key
                            <span className={css.highlight} />,
                        ]}
                        values={{firstName}}
                    />
                </p>
                <div role="presentation" className={css.image}>
                    <div className={css.controlsContainer}>
                        <span className={css.controlsBubble}>
                            <IconHelpDefault className={css.icon} />
                        </span>
                        <span className={css.controlsCopy}>
                            {t('header.help.action')}
                        </span>
                    </div>
                    <div className={css.dropdownContainer}>
                        <HelpDropdown className={css.dropdown} isReadOnly />
                    </div>
                </div>
            </Modal>
            {showTour && (
                <Tour
                    continuous
                    hideBackButton
                    disableScrolling
                    steps={getSteps({t, isNavShowing, hasChatAvailable})}
                    locale={{
                        next: t('common:tour.next'),
                        last: t('common:tour.last'),
                    }}
                    floaterProps={{disableAnimation: true}}
                    onClose={onHidden}
                />
            )}
        </>
    );
};

MainOnboarding.propTypes = {
    isNavShowing: PropTypes.bool.isRequired,
    onHidden: PropTypes.func.isRequired,
};

export default MainOnboarding;
