import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import Image from '@teladoc/pulse/ui/Image';
import Modal from '@teladoc/pulse/ui/Modal';
import Tour from '@teladoc/fe-ccm/ui/common/tour/Tour';
import TourUtils from '@teladoc/fe-ccm/ui/common/tour/tour-utils';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import {getPrimaryRouteById} from '@teladoc/fe-ccm/ui/router/routes';
import DOMUtils from '@livongo/utilities/system/dom';
import cssHeader from '~/header/HeaderPrivate.scss';
import cssNav from '~/sidebar/navigation/Navigation.scss';
import css from './KidneyDiseaseOnboarding.scss';

const dashboard = getPrimaryRouteById('dashboard');
const screenWidth = DOMUtils.width('body');
// the onboarding for chronic kidney disease has different flows
// for screens smaller or larger than $breakpoint-lg: 1025px
const isDesktop = screenWidth >= 1025;

function getSteps({
    t,
    hasDimissed,
    isDashboard,
    isNavShowing,
    hasChatAvailable,
}) {
    const steps = [];

    if (hasDimissed) {
        steps.push({
            target: `.${cssHeader.help}`,
            title: t('onboarding.kidneyDisease.dismissed.title'),
            description: t('onboarding.kidneyDisease.dismissed.description'),
            disableBeacon: true,
        });

        return steps;
    }

    if (isNavShowing) {
        steps.push({
            target: `.${cssNav.mainNav}`,
            title: t('onboarding.kidneyDisease.step1.title'),
            description: t('onboarding.kidneyDisease.step1.description'),
            placement: 'right',
            disableBeacon: true,
        });
    }

    if (isDashboard) {
        steps.push({
            target: `.QuickLinks-reportsAndData`,
            title: t('onboarding.kidneyDisease.step2.title'),
            description: t('onboarding.kidneyDisease.step2.description'),
            disableBeacon: true,
        });
    }

    if (hasChatAvailable) {
        steps.push({
            target: `.${cssHeader.chat}`,
            title: t('onboarding.kidneyDisease.step3.title'),
            description: t('onboarding.kidneyDisease.step3.description'),
            disableBeacon: true,
        });
    }

    if (isDashboard) {
        steps.push({
            target: `.Library-root`,
            title: t('onboarding.kidneyDisease.step4.title'),
            description: t('onboarding.kidneyDisease.step4.description'),
            disableBeacon: true,
        });
    }

    steps.push({
        target: `.${cssHeader.member}`,
        title: t('onboarding.kidneyDisease.step5.title'),
        description: t('onboarding.kidneyDisease.step5.description'),
        disableBeacon: true,
    });

    return steps;
}

const KidneyDiseaseOnboarding = ({isNavShowing, onHidden}) => {
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const {firstName} = useSelector(state => state.user);
    const {hasChatAvailable} = useSelector(state => state.app);
    const [showTour, setShowTour] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [hasDimissed, setHasDimissed] = useState(false);
    const isDashboard = dashboard.path === pathname;
    const onModalClose = () => {
        setShowModal(false);

        if (!isDesktop) {
            onHidden();
        }

        MixpanelUtils.track({event: 'firsttour.ckd.close.clicked'});
    };
    const onTourStart = () => {
        setShowTour(true);
        setShowModal(false);

        MixpanelUtils.track({event: 'firsttour.ckd.next.clicked'});
    };
    const onTourClose = ({action}) => {
        if (hasDimissed || action === TourUtils.ACTIONS.RESET) {
            onHidden();
        } else {
            setShowTour(false);
        }
    };
    const onNext = () => {
        setActiveIndex(index => index + 1);
    };
    const onPrevious = () => {
        setActiveIndex(index => index - 1);
    };

    useEffect(() => {
        // if the intro modal or any tooltip has been dismissed show "revist the tour" notification
        if (isDesktop && !showModal && !showTour && !hasDimissed) {
            setHasDimissed(true);
            setShowTour(true);
        }
    }, [showModal, showTour, hasDimissed]);

    return (
        <>
            {showModal && (
                <div>
                    {isDesktop ? (
                        <Modal
                            isOpen={isDesktop}
                            title={t('onboarding.kidneyDisease.modal1.title')}
                            onRequestClose={onModalClose}
                            primaryAction={{
                                variant: 'primary',
                                onClick: onTourStart,
                                children: t('onboarding.next'),
                            }}
                        >
                            <p className={css.description}>
                                {t(
                                    'onboarding.kidneyDisease.modal1.paragraph1',
                                    {
                                        firstName,
                                    }
                                )}
                            </p>
                            <p className={css.description}>
                                {t(
                                    'onboarding.kidneyDisease.modal1.paragraph2'
                                )}
                            </p>
                            <div className={css.imageContainer}>
                                <Image
                                    cloudinaryImageId="member-portal/dashboard/home/tour/woman-objects_qmfpvb"
                                    width={360}
                                    height={270}
                                    alt=""
                                />
                            </div>
                        </Modal>
                    ) : (
                        <Modal
                            isOpen={!isDesktop}
                            classNameRoot={css.modal}
                            i18nPreviousLabel={t('onboarding.back')}
                            activeContentIndex={activeIndex}
                            onContentIndexChange={onPrevious}
                            onRequestClose={onModalClose}
                            primaryAction={{
                                variant: 'primary',
                                onClick:
                                    activeIndex === 0 ? onNext : onModalClose,
                                children: t(
                                    `onboarding.${
                                        activeIndex === 0 ? 'next' : 'gotIt'
                                    }`
                                ),
                            }}
                            title={t(
                                `onboarding.kidneyDisease.modal${
                                    activeIndex + 1
                                }.title`
                            )}
                        >
                            {activeIndex === 0 && (
                                <>
                                    <div className={css.imageContainer}>
                                        <Image
                                            classNameRoot={css.image}
                                            cloudinaryImageId="member-portal/dashboard/home/tour/woman-objects_qmfpvb"
                                            width={280}
                                            height={224}
                                            alt=""
                                        />
                                    </div>
                                    <p className={css.description}>
                                        {t(
                                            'onboarding.kidneyDisease.modal1.paragraph1',
                                            {
                                                firstName,
                                            }
                                        )}
                                    </p>
                                </>
                            )}
                            {activeIndex === 1 && (
                                <>
                                    <div className={css.imageContainer}>
                                        <Image
                                            classNameRoot={css.image}
                                            cloudinaryImageId="member-portal/dashboard/home/tour/coach-member_mfpq13"
                                            width={280}
                                            height={224}
                                            alt=""
                                        />
                                    </div>
                                    <p className={css.description}>
                                        {t(
                                            'onboarding.kidneyDisease.modal2.paragraph1',
                                            {
                                                firstName,
                                            }
                                        )}
                                    </p>
                                </>
                            )}
                        </Modal>
                    )}
                </div>
            )}
            {showTour && (
                <Tour
                    continuous
                    hideBackButton
                    disableScrolling
                    steps={getSteps({
                        t,
                        hasDimissed,
                        isDashboard,
                        isNavShowing,
                        hasChatAvailable,
                    })}
                    locale={{
                        next: t('common:tour.next'),
                        last: t(
                            hasDimissed
                                ? 'onboarding.gotIt'
                                : 'common:tour.last'
                        ),
                    }}
                    floaterProps={{disableAnimation: true}}
                    onClose={onTourClose}
                />
            )}
        </>
    );
};

KidneyDiseaseOnboarding.propTypes = {
    isNavShowing: PropTypes.bool.isRequired,
    onHidden: PropTypes.func.isRequired,
};

export default KidneyDiseaseOnboarding;
