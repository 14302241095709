import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import IconLanguageDefault from '@teladoc/pulse/icons/language-default.svg';
import Select from '@teladoc/pulse/ui/Select';
import {userUpdate} from '@teladoc/fe-ccm/ui/user/user-actions';
import Logo from '@teladoc/fe-ccm/ui/common/logo/Logo';
import {getPrimaryRouteById} from '@teladoc/fe-ccm/ui/router/routes';
import {DESKTOP_SCREEN_SIZE} from '@teladoc/fe-ccm/ui/config';
import {cnb} from 'cnbuilder';
import Arg from '@livongo/arg';
import DOMUtils from '@livongo/utilities/system/dom';
import css from './HeaderPublic.scss';

const login = getPrimaryRouteById('login');
const localeParam = Arg('locale');

const HeaderPublic = () => {
    const {push} = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {scopedLangCodes} = useI18nContext();
    const {userLocale} = useSelector(state => state.user);
    const {pathname} = useLocation();
    const isAtHomeLabOrder = pathname.includes('non-member');
    const screenWidth = DOMUtils.width('body');
    const isLargeScreenSize = screenWidth >= DESKTOP_SCREEN_SIZE;
    const onLogoClick = () => {
        push(login.path);
    };
    const onLanguageChange = ({value: language}) => {
        dispatch(userUpdate({userLocale: language}));

        // Clear locale if user changes language, but maintain other params if they're passed in.
        if (localeParam) {
            const params = Arg.query();

            if (Object.keys(params).length === 1 && localeParam in params) {
                push(login.path);
            } else {
                delete params.locale;
                push(Arg.url(login.path, params));
            }
        }
    };

    return (
        <div className={css.root}>
            <Logo
                className={cnb(
                    css.logo,
                    !isLargeScreenSize && isAtHomeLabOrder ? css.logoMobile : ''
                )}
                onClick={onLogoClick}
            />
            {!isAtHomeLabOrder && (
                <Select
                    key={userLocale}
                    id="language"
                    name="language"
                    icon={<IconLanguageDefault />}
                    classNameRoot={css.select}
                    classNameItem={css.selectItem}
                    i18nItemLabel={t(
                        'common:modals.languagePreference.language'
                    )}
                    items={scopedLangCodes.map(code => ({
                        label: t(`ui:languages.${code}`),
                        value: code,
                    }))}
                    defaultValue={userLocale}
                    onChange={onLanguageChange}
                />
            )}
        </div>
    );
};

export default HeaderPublic;
