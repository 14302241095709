import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@teladoc/pulse/ui/Button';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import Modal from '@teladoc/pulse/ui/Modal';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import notify from '@teladoc/pulse/ui/Notification';
import {useTransLoader} from '@teladoc/fe-i18n';
import useMounted from '@livongo/utilities/hooks/useMounted';
import UserAPI from '../../user/user-api';
import css from './LanguageConfirmationModal.scss';

const LanguageConfirmationModal = ({isOpen, onRequestClose}) => {
    const {isMounted} = useMounted();
    const [isLoadingLangs, setIsLoadingLangs] = useState(true);
    const {selectedLang, scopedLangCodes, changeLanguage} = useI18nContext();
    const {t, i18n} = useTransLoader('common');
    const onSwitchToLang = code => () => {
        changeLanguage(code);
        onRequestClose();
    };
    const onKeepCurrentLang = async () => {
        try {
            await UserAPI.updateLanguagePreference(selectedLang);
        } catch (error) {
            notify({
                type: 'critical',
                title: t('notifications.languageConfirmation.errorTitle'),
                message: t('notifications.languageConfirmation.errorMessage'),
            });
        }

        onRequestClose();
    };

    // load up the other languages since we show options in native i18n
    useEffect(() => {
        i18n.loadLanguages(scopedLangCodes).then(() => {
            if (isMounted) {
                setIsLoadingLangs(false);
            }
        });
    }, [i18n, isMounted, scopedLangCodes]);

    return (
        <Modal
            isOpen={isOpen}
            title={t('modals.setLanguage.title', {
                language: t(`ui:languages.${selectedLang}`),
            })}
            onRequestClose={onRequestClose}
            hideCloseButton
        >
            <p>
                {t('modals.setLanguage.description', {
                    language: t(`ui:languages.${selectedLang}`),
                })}
            </p>
            <HorizontalRule spaceTop={24} spaceBottom={24} />
            <div className={css.actions}>
                {!isLoadingLangs && (
                    <>
                        {scopedLangCodes
                            .filter(code => code !== selectedLang)
                            .map(code => (
                                <Button
                                    key={code}
                                    className={css.cancel}
                                    onClick={onSwitchToLang(code)}
                                >
                                    {t('modals.setLanguage.change', {
                                        language: t(`ui:languages.${code}`, {
                                            lng: code,
                                        }),
                                        lng: code,
                                    })}
                                </Button>
                            ))}

                        <Button variant="primary" onClick={onKeepCurrentLang}>
                            {t('modals.setLanguage.stay', {
                                language: t(`ui:languages.${selectedLang}`),
                            })}
                        </Button>
                    </>
                )}
            </div>
        </Modal>
    );
};

LanguageConfirmationModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
};

export default LanguageConfirmationModal;
