import UserAPI from './user-api';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UPDATE = 'USER_UPDATE';

export const userLogout = loggingOut => {
    return {
        type: USER_LOGOUT,
        payload: UserAPI.logout(loggingOut),
    };
};

export const userUpdate = payload => {
    return {
        type: USER_UPDATE,
        payload,
    };
};
