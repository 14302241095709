import {lazy} from 'react';
import {
    coreRoutes as CCMCoreRoutes,
    subNavRoutes,
    programRoutes,
    NOT_FOUND,
    userActionRoutes as CCMUserActionRoutes,
    commonRoutes,
    hiddenRoutes,
    getActiveRoutes,
} from '@teladoc/fe-ccm/ui/router/routes';

const coreRoutes = CCMCoreRoutes.map(route => {
    if (route.id === 'dashboard') {
        return {
            ...route,
            component: lazy(() => import('../dashboard/Dashboard')),
        };
    }

    return route;
});

const teamsUserActionRoutes = [
    {
        id: 'login',
        path: '/login',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../user/Login')),
    },
    {
        id: 'prelogin',
        path: '/pre-login',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../dashboard/PreLoginDashboard')),
    },
    {
        id: 'pending',
        path: '/pending',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../dashboard/Pending')),
    },
    {
        id: 'privacy',
        path: '/privacy',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../privacy/Privacy')),
    },
    {
        id: 'coaching',
        path: '/teams-coaching',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../coaching/Coaching')),
    },
];
const teamsUserActionRoutesIDs = teamsUserActionRoutes.reduce((acc, curr) => {
    if (curr.id) {
        acc.push(curr.id);

        return acc;
    }

    return null;
}, []);

const userActionRoutes = [
    ...CCMUserActionRoutes.filter(
        routes =>
            !teamsUserActionRoutesIDs.some(
                teamsUserActionRoutesID => teamsUserActionRoutesID === routes.id
            )
    ),
    ...teamsUserActionRoutes,
];

function getPrimaryRouteById(id) {
    const match = [
        ...coreRoutes,
        ...subNavRoutes,
        ...userActionRoutes,
        ...hiddenRoutes,
    ].find(route => route.id === id);

    if (!match) {
        throw new Error(`No primary route exists for ${id}.`);
    }

    return match;
}

function getSecondaryRoute({primaryId, secondaryId}) {
    const {tabbedRoutes = [], routes = []} = getPrimaryRouteById(primaryId);
    const match = [...tabbedRoutes, ...routes].find(
        route => route.id === secondaryId
    );

    if (!match) {
        throw new Error(`No secondary route exists for ${secondaryId}.`);
    }

    return match;
}

export {
    coreRoutes,
    subNavRoutes,
    programRoutes,
    NOT_FOUND,
    userActionRoutes,
    commonRoutes,
    hiddenRoutes,
    getPrimaryRouteById,
    getSecondaryRoute,
    getActiveRoutes,
};
