import PropTypes from 'prop-types';
import {Trans, useTranslation} from 'react-i18next';
import Modal from '@teladoc/pulse/ui/Modal';
import ExternalPageLink from '@teladoc/fe-ccm/ui/common/external-page-link/ExternalPageLink';
import ModalImageTitle from '../modal-image-title/ModalImageTitle';

const UnsupportedBrowserModal = ({isOpen, onRequestClose}) => {
    const {t} = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            title={
                <ModalImageTitle
                    title={t('modals.unsupportedBrowser.title')}
                    src="member-portal/common/error_hvdvdf"
                />
            }
            onRequestClose={onRequestClose}
        >
            <p>
                <Trans
                    i18nKey="common:modals.unsupportedBrowser.description"
                    components={[
                        /* eslint-disable react/jsx-key */
                        <a
                            href="https://www.microsoft.com/edge"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ExternalPageLink />
                        </a>,
                        /* eslint-enable react/jsx-key */
                    ]}
                />
            </p>
        </Modal>
    );
};

UnsupportedBrowserModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
};

export default UnsupportedBrowserModal;
