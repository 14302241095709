import {cnb} from 'cnbuilder';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Image from '@teladoc/pulse/ui/Image';
import css from './Logo.scss';

const Logo = ({className, width, height, onClick}) => {
    const {t} = useTranslation();
    const {isOneApp} = useSelector(state => state.app);
    const logoKey = isOneApp ? 'oneappLogo' : 'logo';

    return (
        <Image
            classNameRoot={cnb(css.root, className)}
            cloudinaryImageId="member-portal/common/logo_lf3lrk"
            width={width}
            height={height}
            alt={t(logoKey)}
            aria-label={t(logoKey)}
            role="button"
            tabIndex={0}
            onClick={onClick}
        />
    );
};

Logo.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

Logo.defaultProps = {
    width: 125,
    height: 40,
};

export default Logo;
