import React from 'react';

export const ReactAxeCore = async ({loadScript}) => {
    if (loadScript) {
        const axe = await import('@axe-core/react');
        const ReactDOM = await import('react-dom');

        const config = {
            tagExclude: ['best-practice'],
            disableDeduplicate: true,
        };

        axe.default(React, ReactDOM, 1000, config);
    }
};

export default ReactAxeCore;
