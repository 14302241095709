import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import isAfter from 'date-fns/isAfter';
import parse from 'date-fns/parse';

const locales = {
    'en-US': en,
    'es-US': es,
};

/**
 * Function to return date-fns entire locale object.
 *
 * @param {String} lang - They key name of the locale to use.
 * @example
 * getDateLocale('es'); // returns import of 'date-fns/locale/es'
 * @returns {Object} - Locale from date-fns https://date-fns.org/v2.19.0/docs/Locale
 */
export const getDateLocale = lang => {
    const locale = locales[lang];

    if (!locale) {
        throw new Error(`DateLocale with ${lang} key name does not exist.`);
    }

    return locale;
};

/**
 * Function that takes a timestamp with timezone and returns the string without a timezone.
 * Given "2021-01-20T08:33:57.000-08:00" or "2021-01-20T08:33:57.000+08:00"
 * Return "2021-01-20T08:33:57.000"
 * where you see this means:
 * 1) the date string might be in a different time zone, like in the given example
 * 2) the product requirement is to show the time normalized for ALL time zones
 * 2.1) aka display "8:33" from the given example regardless of user system clock
 * 3) the API is not consistent and wants a timezoneless string as params
 * @param {String} timestamp - The timestamp with timezone.
 * @example
 * removeTzFromTimestamp('2021-01-20T08:33:57.000-08:00'); // returns '2021-01-20T08:33:57.000'
 * @returns {String} - The timestamp without timezone.
 */
export const removeTzFromTimestamp = (timestamp = '') => {
    const seperator = 'T';
    const [date, time] = timestamp.split(seperator);

    return [date, time.split(/[-+]/)[0]].join(seperator);
};

/**
 * Function that takes a time and returns a new Date and a boolean if it's after now
 * @param {Object} time - An object with date, hour, minutes, meridiem as children
 * @example
 * isTimeAfterNow({
 *     date: '03/10/2021',
 *     hour: '9',
 *     minutes: '07',
 *     meridiem: 'PM'
 * }) // returns {isAfterNow: false, dateTime: Date}
 * @returns {Object} - Two items to help determine
 *  isAfterNow - is the time after the current date and time
 *  dateTime - new Date of the time today
 */
export const isTimeAfterNow = ({date, hour, minutes, meridiem}) => {
    const dateTime = parse(
        meridiem ? `${hour}:${minutes} ${meridiem}` : `${hour}:${minutes}`,
        meridiem ? 'h:mm a' : 'H:mm',
        new Date(date)
    );

    return {
        isAfterNow: isAfter(dateTime, new Date()),
        dateTime,
    };
};
