import times from 'lodash/times';
import format from 'date-fns/format';
import mapKeys from 'lodash/mapKeys';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import subDays from 'date-fns/subDays';
import isWithinInterval from 'date-fns/isWithinInterval';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import isUndefined from 'lodash/isUndefined';
import getTime from 'date-fns/getTime';
import startOfMonth from 'date-fns/startOfMonth';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import differenceInMonths from 'date-fns/differenceInMonths';
import {getSecondaryRoute} from '../router/routes';
import {removeTzFromTimestamp} from '../dates';
import WeightAPI from './weight-api';

const DATE_FORMAT = 'MM/dd/yyyy';

const WeightUtils = {
    GROUP_SPANS: [
        {range: 7, span: 'day', spanDays: 1, spanUnit: 7, spanType: 'days'},
        {range: 14, span: 'day', spanDays: 1, spanUnit: 14, spanType: 'days'},
        {range: 30, span: 'week', spanDays: 7, spanUnit: 30, spanType: 'days'},
        {range: 90, span: 'week', spanDays: 7, spanUnit: 90, spanType: 'days'},
        {
            range: 180,
            span: 'month',
            spanDays: 30,
            spanUnit: 6,
            spanType: 'months',
        },
        {
            range: 365,
            span: 'month',
            spanDays: 30,
            spanUnit: 1,
            spanType: 'year',
        },
    ],

    groupByMonth(readings) {
        return groupBy(readings, reading =>
            startOfMonth(
                new Date(removeTzFromTimestamp(reading.measurementTime))
            )
        );
    },

    groupByTimeSpan({readings, startDate, endDate, dayRange, span}) {
        const differenceIn =
            span === 'day'
                ? differenceInDays
                : span === 'week'
                ? differenceInWeeks
                : differenceInMonths;
        const numKeys = Math.ceil(differenceIn(endDate, startDate)) + 1; // when the span is in months, we want to know the data difference as float number
        const {spanDays} = this.GROUP_SPANS.find(
            ({range}) => range === dayRange
        );
        const ranges = [];
        const groupedReadings = {};
        let rangeStart = startOfDay(subDays(endDate, spanDays - 1));
        let rangeEnd = endOfDay(endDate);

        // create date ranges to check reading dates against
        times(numKeys, index => {
            // handling edge case where if the dayRange is 365, only 360 days get accounted for because numKeys is 12 and spanDays is 30
            if (index === numKeys - 1) {
                rangeStart = startDate;
            }

            ranges.push({
                start: rangeStart,
                end: rangeEnd,
            });

            rangeEnd = endOfDay(subDays(rangeStart, 1));
            rangeStart = startOfDay(subDays(rangeEnd, spanDays - 1));

            // This condition avoids setting a startDate previous to the day range (30, 90, 180, or 365 days)
            if (differenceInDays(rangeStart, startDate) < 0) {
                rangeStart = startOfDay(startDate);
            }
        });

        // check each reading to see what range its between
        readings.reverse().forEach(reading => {
            const readingDateTime = new Date(
                removeTzFromTimestamp(reading.measurementTime)
            );

            ranges.forEach(({start, end}) => {
                if (
                    isWithinInterval(new Date(readingDateTime), {
                        start,
                        end,
                    })
                ) {
                    const key = format(start, DATE_FORMAT);

                    if (groupedReadings[key]) {
                        groupedReadings[key].push(reading);
                    } else {
                        groupedReadings[key] = [reading];
                    }
                }
            });
        });

        return groupedReadings;
    },

    formatDataForChart({data, startDate, endDate, dayRange}) {
        const series = [
            {
                className: 'chart-marker-weight',
                data: [],
            },
        ];
        const {span} = this.GROUP_SPANS.find(({range}) => range === dayRange);

        if (!isEmpty(data)) {
            const readings = this.groupByTimeSpan({
                readings: data,
                startDate,
                endDate,
                dayRange,
                span,
            });

            mapKeys(readings, (spanReadings, key) => {
                const totalWeight = spanReadings.reduce(
                    (weight, {normalizedWeightLb}) => {
                        return weight + normalizedWeightLb;
                    },
                    0
                );
                const date = getTime(startOfDay(new Date(key)));

                series[0].data.push([
                    date,
                    Math.round(totalWeight / spanReadings.length),
                ]);
            });
        }

        return series;
    },

    getRangeTitles({t, dayRange}) {
        let range = 'daily';

        if (dayRange === 30 || dayRange === 90) {
            range = 'weekly';
        } else if (dayRange === 180 || dayRange === 365) {
            range = 'monthly';
        }

        const rangeTitle = t(`trends.tableHeading.${range}`);

        return {
            chartTitle: t('trends.tableHeading.weightAvg', {
                range: rangeTitle,
            }),
            rangeTitle,
        };
    },

    getFilterRanges({t, hasWeightManagement}) {
        const days = t('filter.days');

        return [
            ...(!hasWeightManagement
                ? [
                      {
                          label: t('filter.ranges', {value: 7, unit: days}),
                          value: '7',
                      },
                  ]
                : []),
            {
                label: t('filter.ranges', {value: 14, unit: days}),
                value: '14',
            },
            {
                label: t('filter.ranges', {value: 30, unit: days}),
                value: '30',
            },
            {
                label: t('filter.ranges', {value: 90, unit: days}),
                value: '90',
            },
            {
                label: t('filter.ranges', {value: 6, unit: t('filter.months')}),
                value: '180',
            },
            {
                label: t('filter.ranges', {value: 1, unit: t('filter.year')}),
                value: '365',
            },
        ];
    },

    getTickInterval(dayRange) {
        const {spanDays} = this.GROUP_SPANS.find(
            ({range}) => range === dayRange
        );

        return 24 * spanDays * 3600 * 1000;
    },

    getStats({t, statistics, dayRange}) {
        const {stats, overallStats} = statistics;
        const currentWeight = overallStats
            ? {
                  weight: overallStats.currentWeightReading.normalizedWeightLb,
                  unit: t('weight:unit'),
              }
            : null;
        const interval = stats.find(({duration}) => duration === dayRange);
        const change = !isUndefined(interval)
            ? `${interval.change <= 0 ? '' : '+ '}${interval.change.toFixed(1)}`
            : null;

        return {
            currentWeight,
            change,
        };
    },

    getTargets({lowestTarget, suggestedTarget, selfEnteredTarget: userTarget}) {
        const {weight, weightUnit} = userTarget?.target || suggestedTarget;

        return {
            weight,
            unit: weightUnit.toLowerCase(),
            lowest: lowestTarget.weight,
            hasSelfEntered: !isEmpty(userTarget),
            ...(userTarget?.weightReadingId && {
                achievedWeightId: userTarget.weightReadingId,
            }),
        };
    },

    async goToWeightPage({
        push,
        backToPath,
        hasUsedScale,
        isScaleOnboardingDone,
        onModalToggle,
    }) {
        const primaryId = 'weight';
        const overview = getSecondaryRoute({
            primaryId,
            secondaryId: 'overview',
        });
        const getStarted = getSecondaryRoute({
            primaryId,
            secondaryId: 'getStarted',
        });
        let hasUsed = hasUsedScale;

        try {
            if (!hasUsed) {
                hasUsed = !isEmpty(
                    await WeightAPI.getReadings({
                        start: new Date(0),
                        count: 1,
                    })
                );
            }

            if (isScaleOnboardingDone) {
                push(overview.path, {backToPath});
            } else if (hasUsed) {
                push(getStarted.path, {backToPath});
            } else {
                onModalToggle();
            }
        } catch (error) {
            push(overview.path, {backToPath});
        }
    },
};

export default WeightUtils;
