import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const ScreenReader = ({children, type}) => {
    const {t} = useTranslation();

    return (
        <span className="sr-only">
            {children ? children : t(`screenReader.${type}`)}
        </span>
    );
};

ScreenReader.propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(['dialog', 'pdf', 'externalWebPage']),
};

export default ScreenReader;
