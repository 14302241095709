import {useTranslation} from 'react-i18next';
import Image from '@teladoc/pulse/ui/Image';
import LanguageSwitcher from '../../../../common/language-switcher';
import css from './TransitionHeader.scss';

const TransitionHeader = () => {
    const {t} = useTranslation();

    return (
        <div className={css.root}>
            <div className={css.header}>
                <Image
                    cloudinaryImageId="registration/common/Teladoc_Logo_ES.svg"
                    alt={t('TeladocHealthLogo')}
                    width={125}
                    height={40}
                />
                <span className={css.verticalLine} />
                <span className={css.spacing}>
                    <Image
                        cloudinaryImageId="member-portal/common/logo_lf3lrk"
                        alt={t('LivongoLogo')}
                        width={146}
                        height={36}
                    />
                </span>
            </div>

            <LanguageSwitcher transitionView />
        </div>
    );
};

export default TransitionHeader;
