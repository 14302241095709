import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {Suspense, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useIdleTimer} from 'react-idle-timer';
import {cnb} from 'cnbuilder';
import Button from '@teladoc/pulse/ui/Button';
import Loader from '@teladoc/fe-ccm/ui/loader/Loader';
import RoutePrivate from '@teladoc/fe-ccm/ui/router/RoutePrivate';
import NoMatch from '@teladoc/fe-ccm/ui/router/NoMatch';
import ScreenReader from '@teladoc/fe-ccm/ui/common/screen-reader/ScreenReader';
import LanguageConfirmationModal from '@teladoc/fe-ccm/ui/common/language-confirmation-modal/LanguageConfirmationModal';
import SessionExpiredModal from '@teladoc/fe-ccm/ui/common/session-expired-modal/SessionExpiredModal';
import {
    coreRoutes,
    commonRoutes,
    subNavRoutes,
    hiddenRoutes,
    getPrimaryRouteById,
} from '@teladoc/fe-ccm/ui/router/routes';
import {useFeatureFlagContext} from '@livongo/utilities/system/featureFlag';
import Sidebar from '~/sidebar/Sidebar';
import HeaderPrivate from '~/header/HeaderPrivate';
import FooterPrivate from '~/footer/FooterPrivate';
import config from '~/config';
import css from './App.scss';

const dashboard = getPrimaryRouteById('dashboard');
const mainRoutes = [
    ...coreRoutes,
    ...subNavRoutes,
    ...commonRoutes,
    ...hiddenRoutes,
];
const relativeRoutes = mainRoutes.filter(route => route.isRelative);

const App = ({showLanguageModal, onLanguageModalToggle}) => {
    const {t} = useTranslation();
    const {push} = useHistory();
    const {pathname} = useLocation();
    const {updateFeatureFlag} = useFeatureFlagContext();
    const {activePrograms, lastActivityTimestamp} = useSelector(
        state => state.user
    );
    const [showIdleModal, setShowIdleModal] = useState(false);
    const [isSubnavShowing, setIsSubnavShowing] = useState(false);
    const [isSidebarShowing, setIsSidebarShowing] = useState(false);
    const [isSkipToContentActivated, setIsSkipToContentActivated] =
        useState(false);
    const showTimestamp =
        lastActivityTimestamp &&
        pathname.includes(getPrimaryRouteById('profilePreferences').path);
    const contentClasses = cnb(css.content, {
        [css.skipToContentActivated]: isSkipToContentActivated,
    });
    const contentContainerClasses = cnb(css.contentContainer, {
        [css.withTimestamp]: showTimestamp,
    });
    const isConfirmLabOrder =
        pathname.includes(getPrimaryRouteById('labOrders').path) ||
        pathname.includes(getPrimaryRouteById('campaignEnded').path) ||
        pathname.includes(getPrimaryRouteById('confirmAddressMembers').path) ||
        pathname.includes(getPrimaryRouteById('confirmOrderMembers').path);
    const onIdle = () => {
        // only show session expiration modal after 14 minutes of idle time if not in development
        if (process.env.ENVIRONMENT !== 'local') {
            setShowIdleModal(true);
        }
    };
    const onStayClick = () => {
        setShowIdleModal(false);
    };
    const onNavToggle = evt => {
        if (!isSidebarShowing) {
            document.querySelector('body')?.classList?.add('Sidebar-showing');
        } else {
            document
                .querySelector('body')
                ?.classList?.remove('body', 'Sidebar-showing');
        }

        setIsSidebarShowing(!isSidebarShowing);
    };
    const onSkipNavigation = () => {
        // https://axesslab.com/skip-links/
        // accessibility solution to skip repetitive elements on the pages such as items from the navigation menu.
        // add a body class that indicates focus on the main content for users that have activated this.
        setIsSkipToContentActivated(true);

        setTimeout(() => {
            // add a slight delay to allow the state change to execute properly
            document.querySelector('[role="main"]')?.focus();
        }, 100);
    };
    const onLogoClick = evt => {
        setIsSidebarShowing(false);
        push(dashboard.path);
    };
    const onKeyDownHandler = e => {
        e.stopPropagation();

        if (e.nativeEvent.key === 'Enter' || e.nativeEvent.Key === 'Spacebar') {
            onNavToggle();
        }
    };

    useIdleTimer({
        element: document,
        debounce: 1000,
        timeout: 840000, // 14 minutes
        onIdle,
    });

    useEffect(() => {
        setIsSubnavShowing(
            subNavRoutes
                .map(({path}) => path)
                .filter(
                    path =>
                        pathname.includes(path) &&
                        `/${pathname.split('/')[1]}` === path
                ).length > 0
        );
    }, [pathname]);

    // This window object is used to update FeatureFlag Toggle via console.
    if (!config.IS_PROD) {
        window.updateFeatureFlag = ({
            featureName,
            oneAppActive = false,
            legacyActive = false,
        }) => {
            return updateFeatureFlag({
                featureName,
                oneAppActive,
                legacyActive,
            });
        };
    }

    return (
        <Suspense fallback={<Loader />}>
            <div className={css.root}>
                <Button
                    variant="secondary"
                    onClick={onSkipNavigation}
                    className={css.skipNavigation}
                >
                    {t('skip')}
                </Button>
                {showLanguageModal && (
                    <LanguageConfirmationModal
                        isOpen={showLanguageModal}
                        onRequestClose={onLanguageModalToggle}
                    />
                )}
                {!showLanguageModal && (
                    <div className={contentContainerClasses}>
                        <HeaderPrivate
                            isNavShowing={
                                !isEmpty(activePrograms) && !isSubnavShowing
                            }
                            isSidebarShowing={isSidebarShowing}
                            onNavToggle={onNavToggle}
                            onLogoClick={onLogoClick}
                            isControlShowing={!isConfirmLabOrder}
                        />
                        {isSidebarShowing && (
                            <div
                                className={css.sidebarShim}
                                role="button"
                                onKeyDown={onKeyDownHandler}
                                onClick={onNavToggle}
                                tabIndex={0}
                            >
                                <ScreenReader />
                            </div>
                        )}
                        {!isConfirmLabOrder && (
                            <Sidebar
                                isOpen={isSidebarShowing}
                                isNavShowing={!showLanguageModal}
                                isSubnavShowing={isSubnavShowing}
                                onLogoClick={onLogoClick}
                                onNavItemClick={onNavToggle}
                            />
                        )}
                        <div
                            role="main"
                            className={contentClasses}
                            {...(isSkipToContentActivated && {
                                tabIndex: -1,
                            })}
                        >
                            <Suspense fallback={<Loader />}>
                                <Switch>
                                    {relativeRoutes.map(
                                        ({
                                            id,
                                            path,
                                            programSlugs,
                                            component: Component,
                                        }) => {
                                            const props = {
                                                id,
                                                path,
                                                component: Component,
                                                ...(programSlugs && {
                                                    programSlugs,
                                                }),
                                            };

                                            return (
                                                <RoutePrivate
                                                    key={id}
                                                    {...props}
                                                />
                                            );
                                        }
                                    )}
                                    <Route component={NoMatch} />
                                </Switch>
                            </Suspense>
                            {showIdleModal && (
                                <SessionExpiredModal
                                    isOpen={showIdleModal}
                                    onStay={onStayClick}
                                />
                            )}
                        </div>
                    </div>
                )}
                <FooterPrivate />
            </div>
        </Suspense>
    );
};

App.propTypes = {
    showLanguageModal: PropTypes.bool,
    onLanguageModalToggle: PropTypes.func.isRequired,
};

export default App;
