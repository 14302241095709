import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import Image from '@teladoc/pulse/ui/Image';
import Modal from '@teladoc/pulse/ui/Modal';
import Tour from '@teladoc/fe-ccm/ui/common/tour/Tour';
import TourUtils from '@teladoc/fe-ccm/ui/common/tour/tour-utils';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import {getPrimaryRouteById} from '@teladoc/fe-ccm/ui/router/routes';
import DOMUtils from '@livongo/utilities/system/dom';
import cssHeader from '~/header/HeaderPrivate.scss';
import cssNav from '~/sidebar/navigation/Navigation.scss';
import css from './HeartFailureOnboarding.scss';

const {
    ACTIONS: {NEXT, RESET},
} = TourUtils;
const dashboard = getPrimaryRouteById('dashboard');
const screenWidth = DOMUtils.width('body');
// the onboarding for chronic kidney disease has different flows
// for screens smaller or larger than $breakpoint-lg: 1025px
const isDesktop = screenWidth >= 1025;

const IMAGES = [
    {
        imageSrc: 'woman-objects_qmfpvb',
    },
    {
        imageSrc: 'member-blood-pressure_voujpy',
    },
    {
        imageSrc: 'nurse_dg3umk',
    },
    {
        imageSrc: 'coach-member_mfpq13',
    },
];

function getSteps({
    t,
    hasDimissed,
    isDashboard,
    isNavShowing,
    hasChatAvailable,
}) {
    const steps = [];
    const disableBeacon = true;

    if (hasDimissed) {
        steps.push({
            disableBeacon,
            target: `.${cssHeader.help}`,
            title: t('onboarding.heartFailure.dismissed.title'),
            description: t('onboarding.heartFailure.dismissed.description'),
        });

        return steps;
    }

    if (isNavShowing) {
        steps.push({
            disableBeacon,
            placement: 'right',
            target: `.${cssNav.mainNav}`,
            title: t('onboarding.heartFailure.step1.title'),
            description: t('onboarding.heartFailure.step1.description'),
        });
    }

    if (isDashboard) {
        steps.push({
            disableBeacon,
            placement: 'left',
            target: `.QuickLinks-reportsAndData`,
            title: t('onboarding.heartFailure.step2.title'),
            description: t('onboarding.heartFailure.step2.description'),
        });
    }

    if (hasChatAvailable) {
        steps.push({
            disableBeacon,
            target: `.${cssHeader.chat}`,
            title: t('onboarding.heartFailure.step3.title'),
            description: t('onboarding.heartFailure.step3.description'),
        });
    }

    if (isDashboard) {
        steps.push({
            disableBeacon,
            placement: 'left',
            target: `.Library-root`,
            title: t('onboarding.heartFailure.step4.title'),
            description: t('onboarding.heartFailure.step4.description'),
        });
    }

    steps.push({
        disableBeacon,
        target: `.${cssHeader.member}`,
        title: t('onboarding.heartFailure.step5.title'),
        description: t('onboarding.heartFailure.step5.description'),
    });

    return steps;
}

const HeartFailureOnboarding = ({isNavShowing, onHidden}) => {
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const {hasChatAvailable} = useSelector(state => state.app);
    const [showTour, setShowTour] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [hasDimissed, setHasDimissed] = useState(false);
    const isDashboard = dashboard.path === pathname;
    const onModalClose = () => {
        MixpanelUtils.track({
            event: 'heartFailure.onboarding.dismiss.clicked',
            properties: {
                Step: activeIndex + 1,
                'Step Type': 'MODAL',
            },
        });

        setShowModal(false);

        if (!isDesktop) {
            onHidden();
        }
    };
    const onTourStart = () => {
        setShowModal(false);

        if (isDesktop) {
            setShowTour(true);
        }

        // The last "Next" button click, on the last step of the modal, opens the tour with tourtips
        MixpanelUtils.track({
            event: 'heartFailure.onboarding.next.clicked',
            properties: {
                Step: activeIndex + 1,
                'Step Type': 'MODAL',
            },
        });
    };
    const onTourChange = ({action, status, lifecycle, index}) => {
        // The logic below avoids tracking any event other than clicking "Next" in a tourtip
        if (
            action === NEXT &&
            status === 'finished' &&
            lifecycle === 'complete'
        ) {
            MixpanelUtils.track({
                event: 'heartFailure.onboarding.next.clicked',
                properties: {
                    Step: index + 1,
                    'Step Type': 'TOURTIP',
                },
            });
        }
    };
    const onTourClose = ({action, index}) => {
        if (hasDimissed || action === RESET) {
            onHidden();
        } else {
            setShowTour(false);

            MixpanelUtils.track({
                event: 'heartFailure.onboarding.dismiss.clicked',
                properties: {
                    Step: index + 1,
                    'Step Type': 'TOURTIP',
                },
            });
        }
    };
    const onNext = () => {
        MixpanelUtils.track({
            event: 'heartFailure.onboarding.next.clicked',
            properties: {
                Step: activeIndex + 1,
                'Step Type': 'MODAL',
            },
        });

        setActiveIndex(index => index + 1);
    };
    const onPrevious = () => {
        setActiveIndex(index => index - 1);
    };

    useEffect(() => {
        // if the intro modals or any tooltip has been dismissed show "revist the tour" notification
        if (isDesktop && !showModal && !showTour && !hasDimissed) {
            setHasDimissed(true);
            setShowTour(true);
        }
    }, [showModal, showTour, hasDimissed]);

    const {imageSrc} = IMAGES[activeIndex];
    const isLast = activeIndex === IMAGES.length - 1;

    return (
        <>
            <Modal
                isOpen={showModal}
                classNameRoot={css.modal}
                i18nPreviousLabel={t('onboarding.back')}
                activeContentIndex={activeIndex}
                onRequestClose={onModalClose}
                onContentIndexChange={onPrevious}
                title={t(
                    `onboarding.heartFailure.modal${activeIndex + 1}.title`
                )}
                primaryAction={{
                    variant: 'primary',
                    onClick: isLast ? onTourStart : onNext,
                    children: t(`onboarding.${isLast ? 'gotIt' : 'next'}`),
                }}
            >
                <Image
                    key={activeIndex}
                    classNameRoot={css.imageContainer}
                    classNameImage={css.image}
                    cloudinaryImageId={`member-portal/dashboard/home/tour/${imageSrc}`}
                    width={360}
                    height={270}
                    alt=""
                />
                <p className={css.description}>
                    {t(
                        `onboarding.heartFailure.modal${
                            activeIndex + 1
                        }.paragraph`
                    )}
                </p>
            </Modal>
            {showTour && (
                <Tour
                    continuous
                    hideBackButton
                    disableScrolling
                    steps={getSteps({
                        t,
                        hasDimissed,
                        isDashboard,
                        isNavShowing,
                        hasChatAvailable,
                    })}
                    locale={{
                        next: t('common:tour.next'),
                        last: t(
                            hasDimissed
                                ? 'onboarding.gotIt'
                                : 'common:tour.last'
                        ),
                    }}
                    floaterProps={{disableAnimation: true}}
                    onChange={onTourChange}
                    onClose={onTourClose}
                />
            )}
        </>
    );
};

HeartFailureOnboarding.propTypes = {
    isNavShowing: PropTypes.bool.isRequired,
    onHidden: PropTypes.func.isRequired,
};

export default HeartFailureOnboarding;
