import omit from 'lodash/omit';
import {createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import {composeWithDevTools} from 'redux-devtools-extension';
import SentryUtils from '@livongo/utilities/system/sentry';
import {USER_UPDATE} from '../user/user-actions';
import {PII_STATE_EXCLUSIONS} from '../user/user-reducer';
import rootReducer from './reducers';

let store;

export default function create(initialState) {
    const sentryReduxEnhancer = SentryUtils.createReduxEnhancer({
        actionTransformer: action => {
            if (action.type === USER_UPDATE) {
                return {
                    ...action,
                    payload: omit(action.payload, PII_STATE_EXCLUSIONS),
                };
            }

            return action;
        },
        stateTransformer: state => {
            return {
                ...state,
                user: omit(state.user, PII_STATE_EXCLUSIONS),
            };
        },
    });

    store = createStore(
        rootReducer,
        initialState,
        composeWithDevTools(
            applyMiddleware(thunkMiddleware, promiseMiddleware),
            ...(sentryReduxEnhancer ? [sentryReduxEnhancer] : [])
        )
    );

    return store;
}

export const getStore = () => {
    return store;
};
