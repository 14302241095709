import isEmpty from 'lodash/isEmpty';
import SendBirdChat from '@sendbird/chat';
import {
    GroupChannelModule,
    GroupChannelHandler,
} from '@sendbird/chat/groupChannel';
import {getStore} from '../store';
import ProtoUtils from '../common/utilities/proto-utils';
import {chatUpdateMessages} from './chat-actions';
import ChatUtils from './chat-utils';

const {
    Chat: {
        ChatClient,
        ChatType: {
            Values: {ONE_ON_ONE, FOOD_LOGGING},
        },
        CreateOneOnOneChatRequest,
        CreateRequestCoachRequest,
        GetSendBirdUserCredentialsRequest,
    },
    Google: {BoolValue},
    getHeaders,
} = ProtoUtils;
const {
    CHANNEL_TYPES: {COACHING, FOOD},
} = ChatUtils;
const CHANNEL_MAP = {
    [COACHING]: ONE_ON_ONE,
    [FOOD]: FOOD_LOGGING,
};
const GLOBAL_HANDLER = 'GLOBAL_HANDLER';
let sb;

const ChatAPI = {
    init(appId) {
        const params = {
            appId,
            modules: [new GroupChannelModule()],
        };

        sb = SendBirdChat.init(params);
    },

    // Returns SendBird user credentials. If a user has never initiated a chat before, this will error with "User not found"
    getCredentials() {
        const request = new GetSendBirdUserCredentialsRequest();

        request.setName('user/liv/me/chat');

        return ChatClient.getSendBirdUserCredentials(request, getHeaders());
    },

    createUser(firstMessage) {
        const request = new CreateOneOnOneChatRequest();

        request.setParent('user/liv/me/chat');
        request.setSubject(firstMessage);

        return ChatClient.createOneOnOneChat(request, getHeaders());
    },

    requestCoach({channelType, lastMessage, requestPreviousCoach = false}) {
        const request = new CreateRequestCoachRequest();

        request.setParent('user/liv/me/chat');
        request.setChatType(CHANNEL_MAP[channelType]);
        request.setSubject(lastMessage);

        if (requestPreviousCoach) {
            const val = new BoolValue();

            val.setValue(true);
            request.setConnectPreviousCoach(val);
        }

        return ChatClient.createRequestCoach(request, getHeaders());
    },

    connect({userId, token}) {
        return new Promise((resolve, reject) => {
            sb.connect(userId, token).then(user => {
                if (user) {
                    const store = getStore();

                    const groupChannelHandler = new GroupChannelHandler({
                        onMessageReceived: (channel, message) => {
                            store.dispatch(
                                chatUpdateMessages({
                                    channel,
                                    message,
                                })
                            );
                        },
                    });

                    sb.groupChannel.addGroupChannelHandler(
                        GLOBAL_HANDLER,
                        groupChannelHandler
                    );

                    return resolve(user);
                } else {
                    reject(user);
                }
            });
        });
    },

    disconnect() {
        return new Promise((resolve, reject) => {
            sb.disconnect((response, error) => {
                if (error) {
                    reject(error);
                } else {
                    sb.removeChannelHandler(GLOBAL_HANDLER);

                    resolve();
                }
            });
        });
    },

    async getChannelById(id) {
        try {
            const channel = await sb?.groupChannel?.getChannel(id);

            return channel;
        } catch (error) {
            return error;
        }
    },

    async loadChannels() {
        const params = {
            limit: 30,
            includeEmpty: true,
            order: 'latest_last_message',
        };

        try {
            const query =
                sb?.groupChannel?.createMyGroupChannelListQuery(params);
            const channels = await query?.next();

            return channels;
        } catch (error) {
            return error;
        }
    },

    async getChannelMeta({channel, keys = []}) {
        try {
            const result = await channel.getMetaData(keys);

            return result;
        } catch (error) {
            return error;
        }
    },

    async loadMessages({
        channel,
        channelMessages,
        isInitial = false,
        limit = 200,
    }) {
        try {
            await channel?.refresh();

            let channelMessageData = !isEmpty(channelMessages)
                ? {...channelMessages[channel.name]}
                : null;

            if (isInitial || !channelMessageData) {
                channelMessageData = {
                    query: channel?.createPreviousMessageListQuery({
                        limit,
                        reverse: true,
                    }),
                    messages: [],
                };
            }

            if (
                channelMessageData.query.hasNext &&
                !channelMessageData.query.isLoading
            ) {
                const messages = await channelMessageData.query.load();

                channelMessageData.messages.push(...messages);
            }

            return {channel, data: channelMessageData};
        } catch (error) {
            return error;
        }
    },

    sendMessage({channel, message}) {
        return new Promise((resolve, reject) => {
            channel
                .sendUserMessage({
                    message,
                })
                .onPending(m => {
                    resolve(m);
                })
                .onFailed((_, m) => {
                    reject(m);
                })
                .onSucceeded(m => {
                    resolve(m);
                });
        });
    },
};

export default ChatAPI;
