import camelCase from 'lodash/camelCase';
import isUndefined from 'lodash/isUndefined';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTransLoader} from '@teladoc/fe-i18n';

export default function useTitle({
    id,
    path,
    parentPath,
    isPublicRoute = false,
}) {
    const CONDITION_MANAGEMENT_PATH = '/condition-management';
    let pathHasJSONFile = true;
    const isCCMHome = path === '/dashboard';
    const isOneAppConditionManagementHome =
        path === `${CONDITION_MANAGEMENT_PATH}/home`;
    const isTeladocSiteMap =
        path === '/sitemap' && window.location.hostname.includes('teladoc');
    const isSubPath = !isUndefined(parentPath);

    // Some oneapp paths, like ones that just hold iframes or websdk views, won't have their own JSON files.
    // we'll check for those here and handle the document title for those in the app.json file.
    const pathsWithoutDedicatedJSONFiles = [
        '/mental-health',
        '/home',
        '/health-info',
        '/account',
        '/message-center',
        `${CONDITION_MANAGEMENT_PATH}/condition-profile`,
    ];

    pathsWithoutDedicatedJSONFiles.forEach(mappedPath => {
        if (path.includes(mappedPath)) {
            // remove 'condition-management' from the JSON key, so we'll just look for the subpath
            // (oneapp doesn't really have subpaths/parent paths, so handling that case this way!)
            // eslint-disable-next-line no-param-reassign
            path = mappedPath.includes(CONDITION_MANAGEMENT_PATH)
                ? mappedPath.substring(CONDITION_MANAGEMENT_PATH.length)
                : mappedPath;
            pathHasJSONFile = false;
        }
    });

    const getTranslationFileName = () => {
        if (isPublicRoute) {
            return 'user';
        }

        if (isSubPath) {
            return parentPath;
        }

        if (isCCMHome) {
            return CONDITION_MANAGEMENT_PATH;
        }

        if (isTeladocSiteMap) {
            return 'oneapp-sitemap';
        }

        if (!pathHasJSONFile) {
            return 'app';
        }

        if (path.includes('confirm-lab-order/members')) {
            return 'lab-order';
        }

        return path;
    };
    const {t} = useTransLoader(getTranslationFileName());
    const {userLocale} = useSelector(state => state.user);
    const {isOneApp} = useSelector(state => state.app);
    const i18nKey = `${
        isPublicRoute || isSubPath
            ? `${id}.`
            : !pathHasJSONFile
            ? `pageTitles.${camelCase(path.substring(1))}.`
            : ''
    }`;

    useEffect(() => {
        document.title = `${t(
            `${i18nKey}${
                isCCMHome || isOneAppConditionManagementHome
                    ? 'legacyDocumentTitle'
                    : 'documentTitle'
            }`,
            t(`${i18nKey}heading`) // if documentTitle translation key doesn't exist load the page heading
        )} - ${isOneApp ? 'Teladoc' : 'Livongo'} Health`;
    }, [
        t,
        i18nKey,
        isCCMHome,
        isOneApp,
        userLocale,
        isOneAppConditionManagementHome,
    ]);
}
