import isEmpty from 'lodash/isEmpty';
import APIUtils from '@livongo/utilities/system/api';
import WeightAPI from '../weight/weight-api';
import BloodPressureAPI from '../blood-pressure/blood-pressure-api';
import BloodSugarAPI from '../blood-sugar/blood-sugar-api';
/*
For more details on the API
https://confluence.teladoc.net/pages/viewpage.action?pageId=2976259812
*/

const LaunchpadAPI = {
    /*  get the Launchpad status
        GET v1/users/me/launchpad
        completed: Indicates if all launchpad items are completed
        completionNotified: Indicates if completion of launchpad items is notified (e.g. show confetti etc)
        minimized: Indicates if the launchpad is minimized
        completedItemCount: The count of completed launchpad items
        totalItemCount: The count of total launchpad items
        items: List of items to show on launchpad and if they are completed.
    */
    getStatus() {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/launchpad`,
            null
        ).then(response => response);
    },
    patchStatus(status) {
        return APIUtils.patch(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/launchpad`,
            status
        ).then(response => response);
    },
    /*  This api will reset all health launchpad status excluding calculated status 
        (all items completed, completed items count, total items count etc.) 
        or items marked completed by kafka. It is for testing only and is disabled on PROD.
    */
    delete() {
        return APIUtils.delete(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/launchpad`
        );
    },
    wtActivated() {
        return WeightAPI.getTarget().then(result => result.selfEnteredTarget);
    },
    bgActivated() {
        return BloodSugarAPI.getReadings({
            start: new Date(0),
            count: 1,
            sortKey: 'time',
        }).then(result => !isEmpty(result));
    },
    bpActivated() {
        return BloodPressureAPI.getStatistics().then(result => {
            return !isEmpty(result?.readings);
        });
    },
    async isWtActivated(program) {
        let result = false;

        try {
            if (program?.WM) {
                result = await this.wtActivated();
            }

            return result;
        } catch (err) {
            return result;
        }
    },
    async isBpActivated(program) {
        let result = false;

        try {
            if (program?.HTN) {
                result = await this.bpActivated();
            }

            return result;
        } catch (err) {
            return result;
        }
    },
    async isBgActivate(program) {
        let result = false;

        try {
            if (program?.DM) {
                result = await this.bgActivated();
            }

            return result;
        } catch (err) {
            return result;
        }
    },
    async checkDevicesActivated(programs) {
        try {
            const res = await Promise.all([
                this.isWtActivated(programs),
                this.isBpActivated(programs),
                this.isBgActivate(programs),
            ]);
            const [WT, BP, BG] = res;

            return {
                WT,
                BP,
                BG,
            };
        } catch (err) {
            return null;
        }
    },
};

export default LaunchpadAPI;
