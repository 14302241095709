import {cnb} from 'cnbuilder';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import differenceInYears from 'date-fns/differenceInYears';
import ExternalPageLink from '@teladoc/fe-ccm/ui/common/external-page-link/ExternalPageLink';
import {useL10nDateTimeContext} from '@teladoc/pulse/ui/Context/l10n/DateTime';
import {
    getPrimaryRouteById,
    getCommonRouteById,
} from '@teladoc/fe-ccm/ui/router/routes';
import Link from '@teladoc/fe-ccm/ui/router/Link';
import config from '~/config';
import css from './Footer.scss';

const FooterPrivate = () => {
    const {intlFormat} = useL10nDateTimeContext();
    const {pathname} = useLocation();
    const {t} = useTranslation();
    const {birthDate, userLocale, lastActivityTimestamp} = useSelector(
        state => state.user
    );
    const age = differenceInYears(new Date(), new Date(birthDate));
    const AGE_LIMIT = 13;
    const showTimestamp =
        lastActivityTimestamp &&
        pathname.includes(getPrimaryRouteById('profilePreferences').path);

    return (
        <footer className={css.root}>
            <div
                className={cnb(css.container, css.privateContainer, {
                    [css.withTimestamp]: showTimestamp,
                })}
            >
                <div className={css.company}>&copy; Teladoc Health, Inc.</div>
                <div className={css.linksWrapper}>
                    <p className={css.links}>
                        <span className={css.link}>
                            <a
                                href={config.TERMS_OF_SERVICE_URL[userLocale]}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={t('footer.terms')}
                            >
                                {t('footer.terms')}
                            </a>
                            <ExternalPageLink className={css.externalLink} />
                        </span>
                        <span className={css.link}>
                            <a
                                href={config.PRIVACY_POLICY_URL[userLocale]}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('footer.privacy')}
                            </a>
                            <ExternalPageLink className={css.externalLink} />
                        </span>
                        <span className={css.link}>
                            <a
                                href={config.PRIVACY_PRACTICES_URL[userLocale]}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('footer.privacyPractices')}
                            </a>
                            <ExternalPageLink className={css.externalLink} />
                        </span>
                        {age < AGE_LIMIT && (
                            <span className={css.link}>
                                <a
                                    href={config.COPA_PRIVACY_URL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('footer.childrenPrivacy')}
                                </a>
                                <ExternalPageLink
                                    className={css.externalLink}
                                />
                            </span>
                        )}
                        <Link
                            className={css.link}
                            to={getCommonRouteById('sitemap').path}
                        >
                            {t('footer.sitemap')}
                        </Link>
                    </p>
                </div>
                <div className={css.version}>
                    {t('footer.version')} {process.env.VERSION}
                </div>
            </div>
            {showTimestamp && (
                <div className={css.timestamp}>
                    <p>
                        {t('footer.timestamp')}
                        {intlFormat(
                            new Date(parseInt(lastActivityTimestamp, 10)),
                            {
                                month: 'numeric',
                                day: 'numeric',
                                year: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric',
                            }
                        )}
                    </p>
                </div>
            )}
        </footer>
    );
};

export default FooterPrivate;
