import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';

const MESSAGE_ITEM_TYPES = {
    LOADER: 'loader',
    DATE: 'date',
    MESSAGE: 'message',
    STATUS: 'status',
    UNREAD_MARKER: 'unreadMarker',
};

function noop() {
    return;
}

function createDate(date, intlFormat) {
    const instance = new Date(date);

    return {
        type: MESSAGE_ITEM_TYPES.DATE,
        instance,
        value: intlFormat(instance, {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }),
    };
}

const ChatUtils = {
    NEW_CHANNEL_URL: 'new',

    CHANNEL_TYPES: {
        COACHING: 'MEMBER_ONE_ON_ONE',
        FOOD: 'MEMBER_FOOD_LOGGING',
        SUPPORT: 'MEMBER_SUPPORT',
    },

    USER_TYPES: {
        COACH: 'COACH',
        MEMBER: 'MEMBER',
    },

    STATUS_TYPES: {
        SENT: 'sent',
        CONNECTING: 'connecting',
        CONNECTING_NEW_COACH: 'connectingNewCoach',
        UNAVAILABLE: 'unavailable',
    },

    AVAILABILITY_TYPES: {
        AVAILABLE: {ID: 0, VALUE: 'AVAILABLE'},
        SHORT: {ID: 1, VALUE: 'SHORT_AWAY'},
        LONG: {ID: 2, VALUE: 'LONG_AWAY'},
        EXTENDED: {ID: 3, VALUE: 'EXTENDED_AWAY'},
        INDEFINITE: {ID: 4, VALUE: 'INDEFINITE_AWAY'},
        NO_COACH: {ID: 5, VALUE: 'NO_COACH'},
    },

    MESSAGE_TYPES: {
        ADMIN: 'admin',
    },

    MESSAGE_ITEM_TYPES,

    createDummyCoachingChannel() {
        return {
            customType: ChatUtils.CHANNEL_TYPES.COACHING,
            url: ChatUtils.NEW_CHANNEL_URL,
            unreadMessageCount: 0,
            members: [],
            isFirstInteraction: true,
            startTyping: noop,
            endTyping: noop,
        };
    },

    orderChannels(channels) {
        return orderBy(channels, ['customType'], ['desc']);
    },

    getSenderName(message) {
        const {messageType, data, sender} = message || {};

        return messageType === ChatUtils.MESSAGE_TYPES.ADMIN
            ? data && JSON.parse(data).senderName
            : sender?.nickname;
    },

    getUnreadMeta(channels) {
        const data = {
            totalUnread: 0,
            lastUnreadsFrom: [],
        };

        channels.forEach(({unreadMessageCount, lastMessage}) => {
            if (unreadMessageCount <= 0 && isEmpty(lastMessage)) return;

            const senderName = ChatUtils.getSenderName(lastMessage);

            data.totalUnread += unreadMessageCount;

            // if a user has unread messages from a coach in this channel, grab the last message and save its data
            // so that we can show a notification to them that they have an unread message(s)
            if (lastMessage.messageType === ChatUtils.MESSAGE_TYPES.ADMIN) {
                data.lastUnreadsFrom.push(senderName);
            } else {
                const {
                    sender: {
                        metaData: {user_type: userType},
                    },
                } = lastMessage;

                if (userType === ChatUtils.USER_TYPES.COACH) {
                    const [firstName] = senderName.split(' ');

                    data.lastUnreadsFrom.push(firstName);
                }
            }
        });

        return data;
    },

    formatUserName(fullName) {
        if (!fullName || isEmpty(fullName)) {
            return {
                abbreviated: null,
                initials: null,
            };
        }

        const [first, middle, last] = fullName.split(' ');
        // if middle is present but no last, that means middle is actually last (Bob Jones)
        // if there is a middle initial as part of users first name (Bob E. Jones) then use the last name first letter
        const realLast = !last && middle ? middle : last;
        const lastInitial = realLast ? `${realLast.substr(0, 1)}.` : '';

        return {
            abbreviated: `${first} ${lastInitial}`,
            initials: `${first.substr(0, 1).toUpperCase()}${lastInitial
                .replace('.', '')
                .toUpperCase()}`,
        };
    },

    getInitials(fullname) {
        try {
            const [first = '', middle = '', last = ''] = fullname.split(' ');
            const realLast = !last && middle ? middle : last;
            const lastInitial = realLast ? `${realLast.substr(0, 1)}.` : '';

            return `${first.substr(0, 1).toUpperCase()}${lastInitial
                .replace('.', '')
                .toUpperCase()}`;
        } catch {
            return fullname;
        }
    },

    getUnreadsInOtherChannels(currentChannelName, channels) {
        const remainingChannels = channels.filter(
            item => item.name !== currentChannelName
        );

        return remainingChannels.length
            ? remainingChannels.some(
                  ({unreadMessageCount}) => unreadMessageCount > 0
              )
            : false;
    },

    getCoachAvailability({channel, isAwaitingCoach}) {
        const coaches = ChatUtils.getUsersInChannel({
            channel,
            type: ChatUtils.USER_TYPES.COACH,
        });
        const isAnyCoachAvailable = coaches.some(
            ({metaData: {availability}}) =>
                availability === ChatUtils.AVAILABILITY_TYPES.AVAILABLE.VALUE
        );

        // user is awaiting a coach but there are no coaches in the channel which means this is either the first interaction
        // or a user requested a new coach after all other coaches left the channel
        if (isAwaitingCoach && !coaches.length) {
            return {
                isAvailable: false,
                status: null,
                name: null,
            };
        }

        // user is not awaiting a coach but there are no coaches in this channel
        if (!isAwaitingCoach && !coaches.length) {
            return {
                isAvailable: false,
                status: ChatUtils.AVAILABILITY_TYPES.NO_COACH.VALUE,
                name: null,
            };
        }

        if (isAnyCoachAvailable) {
            // a coach is available
            return {
                isAvailable: true,
                status: ChatUtils.AVAILABILITY_TYPES.AVAILABLE.VALUE,
                name: null,
            };
        } else {
            // coach(es) are in the channel but unavailable, check to see what the shortest status is
            let coachName = null;
            let shortestAway = null;

            coaches.forEach(({nickname, metaData: {availability}}) => {
                const status = find(ChatUtils.AVAILABILITY_TYPES, [
                    'VALUE',
                    availability,
                ]);

                if (status && (!shortestAway || shortestAway > status.ID)) {
                    shortestAway = status.ID;
                    coachName = ChatUtils.formatUserName(nickname).abbreviated;
                }
            });

            return {
                isAvailable: false,
                status: shortestAway
                    ? find(ChatUtils.AVAILABILITY_TYPES, ['ID', shortestAway])
                          .VALUE
                    : 1,
                name: coachName,
            };
        }
    },

    prepareChannelItems({intlFormat, channel, messages, hasMoreMessages}) {
        const channelLastRead = new Date(channel.myLastRead);
        const msgs = [...messages].reverse();
        const firstDate = createDate(
            msgs.length > 0 && msgs[0].createdAt,
            intlFormat
        );
        const items = [firstDate];
        let currentDate = firstDate.instance;
        let hasUnreadMarker = false;

        // if more messages are available, add a loader as the top-most item so if the user scrolls
        // to the top they will see the loader before the next set of messages is loaded in the background
        if (hasMoreMessages) {
            items.unshift({type: MESSAGE_ITEM_TYPES.LOADER});
        }

        msgs.forEach(message => {
            const {
                createdAt = null,
                messageType = null,
                sender = null,
            } = message || {};
            const userType =
                messageType === ChatUtils.MESSAGE_TYPES.ADMIN
                    ? ChatUtils.USER_TYPES.COACH
                    : sender?.metaData.user_type;
            const msgDate = new Date(createdAt);

            // check if this message was sent by a coach and has been read before
            if (
                !hasUnreadMarker &&
                userType === ChatUtils.USER_TYPES.COACH &&
                isAfter(msgDate, channelLastRead)
            ) {
                items.push({type: MESSAGE_ITEM_TYPES.UNREAD_MARKER});

                hasUnreadMarker = true;
            }

            // check if this message requires a new date heading before it
            if (!isSameDay(msgDate, currentDate)) {
                const newDate = createDate(createdAt, intlFormat);

                items.push(newDate);

                currentDate = newDate.instance;
            }

            items.push({
                type: MESSAGE_ITEM_TYPES.MESSAGE,
                value: message,
            });

            if (message?.senderName) {
                message.senderName = ChatUtils.getSenderName(message);
            }
        });

        return items.reverse();
    },

    getUsersInChannel({channel, type = ChatUtils.USER_TYPES.MEMBER}) {
        return channel.members.filter(
            ({metaData}) => metaData.user_type === type
        );
    },

    getLastMemberSentMessage(messages) {
        const userMessages = messages.filter(
            ({sender}) =>
                sender?.metaData.user_type === ChatUtils.USER_TYPES.MEMBER
        );

        return userMessages.length ? userMessages[0].message : '';
    },

    hasLastMessage(channels) {
        let hasMessage = false;

        channels?.forEach(channel => {
            if (!isEmpty(channel?.lastMessage)) {
                hasMessage = true;
            }
        });

        return hasMessage;
    },
};

export default ChatUtils;
