import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import Modal from '@teladoc/pulse/ui/Modal';
import ModalImageTitle from '@teladoc/fe-ccm/ui/common/modal-image-title/ModalImageTitle';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import css from './FoodLogIntroModal.scss';

const FoodLogIntroModal = ({isOpen, onRequestClose, onConfirm}) => {
    const {t} = useTranslation();
    const onClose = () => {
        MixpanelUtils.track({event: 'newfeaturetour.modal.x.clicked'});

        onRequestClose();
    };
    const onCancel = () => {
        MixpanelUtils.track({event: 'newfeaturetour.modal.cancel.clicked'});

        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            title={
                <ModalImageTitle
                    title={t('tour.addFoodLog.title')}
                    src="member-portal/common/info_vshiwq"
                />
            }
            onRequestClose={onClose}
            primaryAction={{
                variant: 'primary',
                onClick: onConfirm,
                children: t('tour.addFoodLog.confirm'),
            }}
            secondaryAction={{
                onClick: onCancel,
                children: t('tour.addFoodLog.cancel'),
            }}
        >
            <p className={css.description}>
                {t('tour.addFoodLog.description')}
            </p>
            <div className={css.imageContainer} role="presentation">
                <Image
                    classNameRoot={css.imageBackground}
                    cloudinaryImageId="member-portal/dashboard/home/tour/add-food-log_ac2zqs"
                    width={240}
                    height={120}
                    alt=""
                />
                <Button className={css.button} variant="primary" size="small">
                    {t('tour.addFoodLog.action')}
                </Button>
            </div>
        </Modal>
    );
};

FoodLogIntroModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default FoodLogIntroModal;
