import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import IconCloseDefault from '@teladoc/pulse/icons/close-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import css from './TourTooltip.scss';

const TourTooltip = ({
    continuous,
    index,
    size,
    step: {
        title,
        description,
        cloudinaryImageId,
        locale: {
            back: backLabel,
            close: closeLabel,
            last: lastLabel,
            next: nextLabel,
        },
        hideBackButton,
    },
    isLastStep,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
}) => {
    const {t} = useTranslation();

    return (
        <div className={css.root} {...tooltipProps}>
            <div className={css.actions}>
                <Button className={css.close} {...closeProps}>
                    <IconCloseDefault />
                </Button>
            </div>
            <h1 className={css.title}>{title}</h1>
            {cloudinaryImageId && (
                <Image
                    classNameRoot={css.image}
                    cloudinaryImageId={cloudinaryImageId}
                    width={288}
                    height={160}
                    alt=""
                />
            )}
            <p className={css.description}>{description}</p>
            <div className={css.footer}>
                {size > 1 && (
                    <span className={css.steps}>
                        {t('common:tour.steps', {
                            current: index + 1,
                            total: size,
                        })}
                    </span>
                )}
                <div className={css.actions}>
                    {!hideBackButton && index > 0 && (
                        <Button
                            variant="primary"
                            size="small"
                            className={css.back}
                            {...backProps}
                        >
                            {backLabel}
                        </Button>
                    )}
                    <Button
                        variant="primary"
                        size="small"
                        {...(continuous ? primaryProps : closeProps)}
                    >
                        {continuous
                            ? isLastStep
                                ? lastLabel
                                : nextLabel
                            : closeLabel}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const commonProps = {
    /** A button aria-label. */
    'aria-label': PropTypes.string.isRequired,
    /** A button data-action. */
    'data-action': PropTypes.string.isRequired,
    /** A button role. */
    role: PropTypes.string.isRequired,
    /** A secondary source for the label of the button. The prop `locale` is our primary source for this label. */
    title: PropTypes.string.isRequired,
    /** The main action of the button.*/
    onClick: PropTypes.func.isRequired,
};

TourTooltip.propTypes = {
    /** The properties of the back button. */
    backProps: PropTypes.shape(commonProps),
    /** The properties of the close button. */
    closeProps: PropTypes.shape(commonProps),
    /** The properties of the main action button. */
    primaryProps: PropTypes.shape(commonProps),
    /** A boolean to control if the next tooltip will be shown right after closing the previos one. */
    continuous: PropTypes.bool,
    /** A boolean to indicate if the current tooltip is the last from a list of steps. */
    isLastStep: PropTypes.bool,
    /** The index of the current tooltip from a list of steps. */
    index: PropTypes.number,
    /** The amount of steps the current tour has. */
    size: PropTypes.number,
    /** An object with the main content to be shown on the tooltip. */
    step: PropTypes.shape({
        /** The title of the tooltip. */
        title: PropTypes.string.isRequired,
        /** The description paragraph of the tooltip. */
        description: PropTypes.string.isRequired,
        /** The image ID that is stored in Cloudinary for this image. */
        cloudinaryImageId: PropTypes.string,
        /** An object with the labels for the action button of the tour tooltip. */
        locale: PropTypes.shape({
            /** The label for the button "back". */
            back: PropTypes.string,
            /** The label for the button "close" of a single-step tour. */
            close: PropTypes.string,
            /** The label for the "last" action button of a multi-step tour. */
            last: PropTypes.string,
            /** The label for the button "next". */
            next: PropTypes.string,
            /** The label for the button "skip". */
            skip: PropTypes.string,
        }),
        hideBackButton: PropTypes.bool,
    }),
    /** A object with the basic properties of the tooltip popup window. */
    tooltipProps: PropTypes.shape({
        /** A aria-model for the tooltip window. */
        'aria-modal': PropTypes.bool,
        /** A React reference for the tootlip window. */
        ref: PropTypes.func.isRequired,
        /** A role for the tooltip window. */
        role: PropTypes.string.isRequired,
    }),
};

export default TourTooltip;
