import {useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {cnb} from 'cnbuilder';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import IconArrowLeftDefault from '@teladoc/pulse/icons/arrow-left-default.svg';
import IconCloseDefault from '@teladoc/pulse/icons/close-default.svg';
import IconMenuDefault from '@teladoc/pulse/icons/menu-default.svg';
import css from './Hamburger.scss';

const Hamburger = ({parentClass, isSidebarShowing, onClick}) => {
    const {t} = useTranslation();
    const {isOneApp} = useSelector(state => state.app);
    const onEscapePress = useCallback(
        evt => {
            if (evt.key === 'Escape' && isSidebarShowing) {
                onClick();
            }
        },
        [isSidebarShowing, onClick]
    );

    useEffect(() => {
        document.addEventListener('keydown', onEscapePress);

        return () => {
            document.removeEventListener('keydown', onEscapePress);
        };
    }, [onEscapePress]);

    return (
        <button
            type="button"
            className={cnb(parentClass, css.root, {
                [css.isOneAppActive]: isOneApp && isSidebarShowing,
                [css.menuIconBorder]: isOneApp && !isSidebarShowing,
            })}
            onClick={onClick}
            aria-label={t('header.menuToggle')}
            aria-expanded={isSidebarShowing ? 'true' : 'false'}
            aria-haspopup="true"
        >
            {isSidebarShowing ? (
                isOneApp ? (
                    <IconArrowLeftDefault
                        className={cnb(css.menuIcon, css.leftArrowIcon)}
                    />
                ) : (
                    <IconCloseDefault className={css.menuIcon} />
                )
            ) : (
                <IconMenuDefault className={css.menuIcon} />
            )}
        </button>
    );
};

Hamburger.propTypes = {
    isSidebarShowing: PropTypes.bool,
    parentClass: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default Hamburger;
