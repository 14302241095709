import PropTypes from 'prop-types';
import css from './ModalImageTitle.scss';

const ModalImageTitle = ({title, src, ...rest}) => {
    return (
        <div className={css.root} {...rest}>
            <h1 className="heading-sm">{title}</h1>
        </div>
    );
};

ModalImageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
};

export default ModalImageTitle;
