import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import {useTransLoader} from '@teladoc/fe-i18n';
import FormGroupError from '@teladoc/pulse/ui/FormGroupError';

const CURRENT_PASSWORD_ERROR = 70;
const ERROR_CODES = {
    REPEATING: 'REPEATING',
    SEQUENTIAL: 'SEQUENTIAL',
};

function formatError(error) {
    // non API provided error messages would be something like a 404 where axios is providing the error message instead
    const nonApiError = error?.data?.message || null;

    if (nonApiError) {
        return {message: nonApiError};
    }

    return error;
}

function formatPasswordError({error, t}) {
    const errorCodes = error?.data?.detail_codes || null;

    if (error?.data?.code === CURRENT_PASSWORD_ERROR) {
        return {message: t('errors.password.currentPassword')};
    }

    if (!errorCodes) {
        return null;
    }

    if (errorCodes.length === 1) {
        return {message: t(`errors.password.${camelCase(errorCodes[0])}`)};
    }

    // We use the same text for these 2 codes, so we need to
    // remove one of them to not repeat the text
    if (
        errorCodes.includes(ERROR_CODES.REPEATING) &&
        errorCodes.includes(ERROR_CODES.SEQUENTIAL)
    ) {
        errorCodes.splice(errorCodes.indexOf(ERROR_CODES.REPEATING), 1);
    }

    return errorCodes.map(code => {
        return {message: t(`errors.password.${camelCase(code)}`)};
    });
}

const FormSaveError = ({error, title, isPasswordError}) => {
    const {t} = useTransLoader('app');

    if (!error) {
        return null;
    }

    const defaultTitle = isArray(error)
        ? t('errors.generic.multiple')
        : t('errors.generic.single');
    const oneOrMoreErrorTitle =
        error?.data?.detail_codes?.length === 1 ||
        error?.data?.code === CURRENT_PASSWORD_ERROR
            ? 'singleError'
            : 'multipleErrors';

    return (
        <FormGroupError
            title={
                isPasswordError
                    ? t(`errors.password.title.${oneOrMoreErrorTitle}`)
                    : title || defaultTitle
            }
            errors={
                isPasswordError
                    ? formatPasswordError({error, t})
                    : formatError(error)
            }
        />
    );
};

FormSaveError.propTypes = {
    error: PropTypes.object,
    title: PropTypes.string,
    isPasswordError: PropTypes.bool,
};

export default FormSaveError;
