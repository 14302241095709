import {ActionType} from 'redux-promise-middleware';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import {USER_LOGOUT, USER_UPDATE} from './user-actions';

export const initialState = {
    // IMPORTANT NOTE: Any part of this state that is PII (personally identifiable information) needs to be added to the `PII_STATE_EXCLUSIONS` array below
    isPending: false,
    error: null,
    mfaToken: null, // required token to authenticate an MFA account
    isAuthenticated: false,
    isManuallyLoggedOut: false,
    isInternational: false,
    isDirectToConsumer: false,
    userId: null,
    publicUUID: null, // used when we want to indentify a user in a service external from Member Portal but need to protect his primary key (userId)
    companyId: null,
    firstName: null,
    lastName: null,
    fullName: null,
    birthDate: null,
    yearRange: null, // possible years a user can select based on birth date
    email: null,
    phoneNumbers: {},
    address: {},
    isPOBoxAllowed: false,
    addressTypes: [], // address types allowed to be shown, 'MILITARY', 'US_TERRITORY'
    userLocale: getStoredLang(),
    hasPersonalizedBPReport: false,
    programs: [],
    activePrograms: {}, // list of active program IDs { bloodSugar: true, bloodPressure: true, ...}
    orders: [],
    bloodSugarDevice: null, // device user has as part of the Diabetes program, 'bg300' or 'inTouch'
    bloodPressureDevice: null, // device user has as part of the Hypertension program, 'htn' or 'ht900'
    weightDevice: null, // device user has as part of the Weight Management program, 'wm1000' or 'wm1500'
    hasDyslipidemia: false,
    hasKidneyDiseaseDiagnosis: false,
    hasSetPersonalGoalWeight: false,
    hasLabA1c: false,
    lastActivityTimestamp: null,
};

// The redux store is hooked up to Sentry to send data about the user's state to help us solve production issues missed during dev/QA.
// The below array is the user data that is excluded from that state, otherwise known as PII.
// We should not be sending ANY PII to Sentry or any third party tool.
export const PII_STATE_EXCLUSIONS = [
    'address',
    'birthDate',
    'email',
    'firstName',
    'lastName',
    'fullName',
    'phoneNumbers',
    'publicUUID',
    'userId',
    'orders',
    'yearRange',
    'lastActivityTimestamp',
];

export default function user(state = initialState, {type, payload}) {
    switch (type) {
        case `${USER_LOGOUT}_${ActionType.Pending}`: {
            return {
                ...state,
                isPending: true,
                error: null,
            };
        }

        case `${USER_LOGOUT}_${ActionType.Fulfilled}`: {
            return {
                ...initialState,
                isManuallyLoggedOut: true,
                userLocale: state.userLocale,
            };
        }

        case `${USER_LOGOUT}_${ActionType.Rejected}`: {
            return {
                ...state,
                isPending: false,
                error: 'Could not log out.',
            };
        }

        case USER_UPDATE: {
            return {
                ...state,
                ...payload,
            };
        }

        default:
            return state;
    }
}
