import PropTypes from 'prop-types';
import Channel from '../cards/Channel';
import css from './Channels.scss';

const Channels = ({data, onChannelSelected}) => (
    <div className={css.root}>
        {data.map(channel => {
            return (
                <Channel
                    key={channel.url}
                    data={channel}
                    onSelected={onChannelSelected}
                />
            );
        })}
        <Channel />
    </div>
);

Channels.propTypes = {
    data: PropTypes.array,
    onChannelSelected: PropTypes.func.isRequired,
};

export default Channels;
