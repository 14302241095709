/* eslint-disable no-console */
/* eslint-disable camelcase */
import 'index.scss';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import ErrorBoundary from '@teladoc/pulse/ui/ErrorBoundary';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import PulseApp, {STATUSES} from '@teladoc/pulse/ui/App';
import {config as i18n} from '@teladoc/fe-i18n';
import ApptentiveUtils from '@teladoc/fe-ccm/ui/common/apptentive/apptentive-utils';
import TeamsUtils, {authentication, app} from '@teladoc/fe-teams/utilities';
import ChatAPI from '@teladoc/fe-ccm/ui/chat/chat-api';
import UserAPICCM from '@teladoc/fe-ccm/ui/user/user-api';
import UserAPITeams from '@teladoc/fe-teams/ui/user/user-api';
import UserUtils from '@teladoc/fe-ccm/ui/user/user-utils';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import Unavailable from '@teladoc/fe-ccm/ui/router/Unavailable';
import createStore from '@teladoc/fe-ccm/ui/store';
import {initialState as appInitialState} from '@teladoc/fe-ccm/ui/app/app-reducer';
import {initialState as userInitialState} from '@teladoc/fe-ccm/ui/user/user-reducer';
import TeamsRoot from '@teladoc/fe-teams/ui/app/Root';
import {
    CLIENT_ID_MS_TEAMS_BOT,
    CLIENT_ID_SERVICE_TEAMS,
    MY_IS_REDIRECT_FROM_TEAMS,
} from '@teladoc/fe-teams/ui/config';
import Arg from '@livongo/arg';
import EnvironmentUtils from '@livongo/utilities/system/environment';
import SentryUtils, {DEFAULT_OPTIONS} from '@livongo/utilities/system/sentry';
import StorageUtils from '@livongo/utilities/system/storage';
import APIUtils from '@livongo/utilities/system/api';
import {FeatureFlagProvider} from '@livongo/utilities/system/featureFlag';
import Root from '~/app/Root';
import config from '~/config';

const featureFlags = require(`@teladoc/fe-feature-flags/packages/environment/${
    process.env.ENVIRONMENT === 'local'
        ? 'integration'
        : process.env.ENVIRONMENT
}.json`);

// - Redirected from an internal entity (registration, upsell, etc)
// https://baseUrl?referrer=internal&access_code=abc123
// - SSO authentication from partners has the following format
// https://baseUrl/#/sso?partner_id=FEPBLUE&utm_medium=referral&utm_content=sso&access_code=abc123
// - Livongo's member support direct link to login into member's accounts
// https://baseUrl/#/isSupport=true&authToken=abc123
// - Link to embed Member Portal as an iframe inside Livongo's Salesforce portal
// https://baseUrl/#/compactDisplay=true&authToken=abc123
// - SSO: the parameters access_code and accessCode are, respectively, from an old and new
// sso flow versions. In Q2 2021 we will stop using access_code
const {
    referrer,
    authToken,
    accessCode, // access code from client websites
    partner_id: partnerId,
    access_code: accessCodeInternal, // access code from Livongo websites like Registration, and Upsell
    source,
    client_id: clientId,
} = Arg.all();
const IS_DEV = process.env.NODE_ENV === 'development';
const GRANT_TYPE = 'authorization_code';
const IS_INTERNAL_REDIRECT = referrer === 'internal' && accessCodeInternal;
// Check if a refresh token was provided by entity outside of this new member portal
// Use the stored refresh token from above or if its null then check if this member portal has a stored refresh token
const STORED_REFRESH_TOKEN =
    StorageUtils.get({
        key: config.MY_REFRESH_COOKIE,
        type: 'cookie',
        useNative: true,
    }) ||
    StorageUtils.get({
        key: config.REFRESH_TOKEN_COOKIE,
        type: 'cookie',
        useNative: true,
        parse: false,
    });

// Temporary solution to provide support for iOS once they have this feature in place (around SEPTEMBER) we will remove this
const STORED_EDUCATION_COOKIE =
    source &&
    StorageUtils.get({
        key: config.EDUCATION_TOKEN_COOKIE,
        type: 'cookie',
        useNative: true,
        parse: false,
    });

// check if the window is redirected from teams app
if (
    clientId === CLIENT_ID_MS_TEAMS_BOT ||
    clientId?.includes(CLIENT_ID_SERVICE_TEAMS)
) {
    StorageUtils.set({
        key: MY_IS_REDIRECT_FROM_TEAMS,
        value: true,
        type: 'session',
        useNative: true,
    });
}

const hasTeamRedirectSession = StorageUtils.get({
    key: MY_IS_REDIRECT_FROM_TEAMS,
    type: 'session',
    useNative: true,
});

const teamsInitialData = {
    app: {
        ...appInitialState,
        isTeamsEnvironment: true,
        isRedirectFromTeams: hasTeamRedirectSession,
    },
    user: userInitialState,
    chat: null,
};

const promises = [];

APIUtils.init({
    baseUrl: process.env.API_URL,
    additionalHeaders: {
        'Content-Type': 'application/json',
        'Accept-Language': getStoredLang(),
    },
});

ChatAPI.init(process.env.SENDBIRD_APP_ID);

MixpanelUtils.init(process.env.MIXPANEL_TOKEN);

SentryUtils.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.BUILD_TAG,
    environment: process.env.ENVIRONMENT,
    whitelistUrls: [/my\.livongo\.com/],
    ignoreErrors: [
        ...DEFAULT_OPTIONS.ignoreErrors,
        // the source of this issue is external to this project and it wasn't identified
        "undefined is not an object (evaluating 'window.webkit.messageHandlers*postMessage')",
        // axe-core issue
        `t.getAttribute is not a function. (In 't.getAttribute("role")', 't.getAttribute' is undefined)`,
        // Microsoft Outlook SafeLink crawler issue
        'Non-Error promise rejection captured',
    ],
});

if (EnvironmentUtils.isSafari()) {
    // Safari is the new IE, we need to be able to target it directly to fix random annoying issues
    document.querySelector('html')?.classList?.add('Safari');
}

if (IS_INTERNAL_REDIRECT) {
    // referred from any internal Livongo entity like Registration, Upsell, etc.
    promises.push(
        UserAPICCM.login({
            grantType: GRANT_TYPE,
            credentials: {code: accessCodeInternal},
        })
    );
} else if (partnerId && (accessCodeInternal || accessCode)) {
    // single sign-on (SSO) takes precedence over any existing logged-in user
    promises.push(
        accessCodeInternal
            ? UserAPICCM.sso({partnerId, accessCode: accessCodeInternal}).then(
                  UserAPICCM.loadInitialData
              )
            : UserAPICCM.login({
                  grantType: GRANT_TYPE,
                  credentials: {
                      code: accessCode,
                  },
              })
    );
} else if (authToken && !hasTeamRedirectSession) {
    // Livongo's member support has access to "direct links" to login into member's accounts
    // Those "direct links" have JWT access tokens that should be set to this application
    UserAPICCM.updateAuthorization({
        data: {
            access_token: authToken, // eslint-disable-line camelcase
        },
    });

    promises.push(UserAPICCM.loadInitialData());
} else if (STORED_EDUCATION_COOKIE && source) {
    // Temporary solution to provide support for iOS once they have this feature in place (around SEPTEMBER) we will remove this
    UserAPICCM.updateAuthorization({data: JSON.parse(STORED_EDUCATION_COOKIE)});
    promises.push(UserAPICCM.loadInitialData());
} else if (STORED_REFRESH_TOKEN && !hasTeamRedirectSession) {
    // if a refresh token exists in local storage, use it to see if it hasn't expired yet and if not then
    // the user is still logged in and we can load their initial data using the STORED_REFRESH_TOKEN
    promises.push(
        UserAPICCM.refreshToken(STORED_REFRESH_TOKEN).then(
            UserAPICCM.loadInitialData
        )
    );
}

// Apptentive initialization is being done separately in case
// the Apptentive SDK fails to load, it won't bring down the whole site
// it is intentional to add to promises array
// as we do NOT want to trigger STATUSES.INITIALIZED in the AppUi render method
// untill Apptentive successfully resolves or fails to resolve
promises.push(
    new Promise((resolve, reject) => {
        (async () => {
            try {
                await ApptentiveUtils.init({
                    appId: process.env.APPTENTIVE_APP_ID,
                    options: {
                        // eslint-disable-next-line camelcase
                        app_release: {
                            version: process.env.VERSION,
                        },
                    },
                });
            } catch (error) {
                if (IS_DEV) {
                    // eslint-disable-next-line no-console
                    console.log('[ERROR: APPTENTIVE]', error);
                }
            }
            // even if Apptentive fails, resolve this promise as a success with no data in the resolve arg
            // as we do NOT want to affect the initialData in the render method of AppUi
            resolve();
        })();
    })
);

promises.push(
    new Promise((resolve, reject) => {
        (async () => {
            try {
                await TeamsUtils.init()
                    .then(({isTeamsEnvironment} = {}) => {
                        if (isTeamsEnvironment) {
                            TeamsUtils.setColorScheme();
                        }

                        return isTeamsEnvironment;
                    })
                    .then(isTeamsEnvironment => {
                        if (!isTeamsEnvironment) {
                            resolve();
                        }

                        if (hasTeamRedirectSession) {
                            resolve(teamsInitialData);
                        } else {
                            const authTokenRequest = {
                                successCallback(token) {
                                    UserAPITeams.getTeamsTokenExchange({
                                        token,
                                        hasTeamRedirectSession,
                                    })
                                        .then(UserAPICCM.updateAuthorization)
                                        .then(data => {
                                            if (data) {
                                                resolve(
                                                    UserAPICCM.loadInitialData(
                                                        data
                                                    )
                                                );
                                            }

                                            return data;
                                        })
                                        .catch(error => {
                                            reject(error);
                                        });
                                },

                                failureCallback(error) {
                                    if (IS_DEV) {
                                        console.log(
                                            `[ERROR: MsTeams Error getting token]: ${error}`
                                        );
                                    }

                                    resolve(teamsInitialData);
                                },
                            };

                            authentication?.getAuthToken(authTokenRequest);
                        }
                    })
                    .catch(error => {
                        throw error;
                    });
            } catch (error) {
                if (IS_DEV) {
                    console.log('[ERROR: MsTeams]', error);
                }
                error.data = {error: 'invalid_teams_token'}; // TODO: Do not hardcode, handle this through BE api.
                reject(error);
            }
        })();
    })
);

render(
    <ErrorBoundary errorComponent={<Unavailable />}>
        <PulseApp
            i18n={{
                ...i18n,
                interpolation: {
                    defaultVariables: {
                        companyName: 'Livongo',
                        inTouchDevice: 'In Touch®',
                    },
                },
            }}
            build={{
                key: 'mp-build-tag',
                value: process.env.BUILD_TAG,
            }}
            promises={promises}
            render={({data, status}) => {
                const isTeams = app?.isInitialized();
                const initialData = data?.[0] || (isTeams && data?.[1]) || {};
                const API_ERROR = data?.data?.error;
                const App = () => (
                    <Provider store={createStore(initialData)}>
                        <FeatureFlagProvider featureFlags={featureFlags}>
                            {isTeams ? <TeamsRoot /> : <Root />}
                        </FeatureFlagProvider>
                    </Provider>
                );

                switch (status) {
                    case STATUSES.INITIALIZED:
                        return App();
                    case STATUSES.ERROR:
                        if (IS_DEV) {
                            // eslint-disable-next-line no-console
                            console.log('[ERROR: INITIAL LOAD]', data);
                        }

                        if (
                            API_ERROR === 'invalid_grant' || // invalid_grant: the username, password, MFA code, or SSO is invalid
                            API_ERROR === 'invalid_token' || // invalid_token: a non-JWT token is sent to the server
                            API_ERROR === 'invalid_client' || // invalid_client: a real JWT from another service is sent to the server
                            API_ERROR === 'invalid_teams_token'
                        ) {
                            // user's prior login info (our refresh token, pre-existing refresh token from other forms of logging in) is invalid
                            // clear those cookies out and load the site without user info
                            UserUtils.logout();

                            return App();
                        } else {
                            return <Unavailable />;
                        }
                }
            }}
        />
    </ErrorBoundary>,
    document.getElementById('root')
);
