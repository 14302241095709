/* eslint-disable no-console */
/* eslint-disable camelcase, no-use-before-define */

import APIUtils, {APIRegistry} from '@livongo/utilities/system/api';

const IS_DEV = process.env.ENVIRONMENT === 'development';

const teamsInstance = APIRegistry.create({
    name: 'teamsEndpoint',
    config: {
        baseUrl:
            process.env.SERVICE_URL ||
            'https://usvc.integration.livongo.com:443',
    },
});

const UserAPI = {
    // valid grantType: 'password', 'mfa-oob', 'authorization_code
    // 'password' credentials: {username, password}
    // 'mfa-oob' credentials: {bindingCode, mfaToken, oobCode}
    // 'authorization_code' credentials: {code}
    login({grantType = 'password', credentials}) {
        const data = {
            grant_type: grantType,
            ...credentials,
        };

        // for post call the client-id is hardcoded since it's always set to ms-teams for teams BOT. The link that BOT opens contains our server's client-id.
        return APIUtils.post(`v1/users/me/auth/oAuth2Code?client_id=ms-teams`, {
            redirect_uri: data?.redirectUri,
            username: data?.username,
            password: data?.password,
            response_type: 'code',
            scope: data?.scope,
            state: data?.state,
        }).then(({data: {code}}) => {
            window.location.replace(
                `${data?.redirectUri}?scope=${data?.scope}&code=${code}&state=${data?.state}`,
                '_self'
            );
        });
    },
    getTeamsTokenExchange({token, hasTeamRedirectSession}) {
        if (!token) {
            if (IS_DEV) {
                console.error('ERROR: No token to exchange');
            }

            return {}; // TODO:  handle for when no token is passed by teams to exchange
        }

        return teamsInstance
            .get('/service-teams/oauth2/tokenExchange', null, {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then(response => {
                if (response?.data) {
                    return {
                        data: {
                            type: 'teams',
                            access_token: response?.data,
                            isRedirectFromTeams: hasTeamRedirectSession,
                        },
                    };
                }

                throw response;
            })
            .catch(error => {
                if (IS_DEV) {
                    console.error('[ERROR MsTeams: invalid authtoken]', error);
                }
                error.data = {error: 'invalid_teams_token'}; // TODO: handle this through api. Use this value to load pre login for Dashboard
                throw error;
            });
    },
};

export default UserAPI;
