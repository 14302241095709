import ProtoUtils from '../../../common/utilities/proto-utils';

const {
    Education: {
        GetCourseSummaryRequest,
        RenderingClient,
        UserClient,
        UserActivityClient,
        GetVideoStatusRequest,
    },
    Google: {Empty, StringValue},
    getHeaders,
} = ProtoUtils;

const CurriculumAPI = {
    getCourse() {
        const courseRequest = new GetCourseSummaryRequest();

        courseRequest.setCurriculumId(1); // TODO: get curriculumId from user data or URL?
        courseRequest.setTimeZone(
            new StringValue().setValue(
                Intl.DateTimeFormat().resolvedOptions().timeZone
            )
        );

        return RenderingClient.getCourseSummary(courseRequest, getHeaders());
    },

    getUrl() {
        return UserClient.getServiceInfo(new Empty(), getHeaders());
    },

    getVideoWatchedStatus() {
        return UserActivityClient.getVideoStatus(
            new GetVideoStatusRequest(),
            getHeaders()
        ).then(response => response.getVideoStatusList());
    },
};

export default CurriculumAPI;
