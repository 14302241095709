import PropTypes from 'prop-types';
import css from './ControlBubble.scss';

const ControlBubble = ({children}) => (
    <span className={css.root}>{children}</span>
);

ControlBubble.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ControlBubble;
