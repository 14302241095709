import ChatAPI from './chat-api';
import ChatUtils from './chat-utils';

export const CHAT_CONNECT = 'CHAT_CONNECT';
export const CHAT_TOGGLE = 'CHAT_TOGGLE';
export const CHAT_LOAD_MESSAGES = 'CHAT_LOAD_MESSAGES';
export const CHAT_UPDATE_MESSAGES = 'CHAT_UPDATE_MESSAGES';
export const CHAT_MARK_CHANNEL_READ = 'CHAT_MARK_CHANNEL_READ';
export const CHAT_SET_CURRENT_CHANNEL = 'CHAT_SET_CURRENT_CHANNEL';
export const CHAT_SET_WAITING_FOR_NEW_COACH = 'CHAT_SET_WAITING_FOR_NEW_COACH';
export const CHAT_UPDATE_UNREAD_COUNT = 'CHAT_UPDATE_UNREAD_COUNT';

export const chatConnect = () => {
    return {
        type: CHAT_CONNECT,
        payload: ChatAPI.getCredentials()
            .then(async credentials => {
                await ChatAPI.connect({
                    userId: credentials.getUserId(),
                    token: credentials.getSessionToken(),
                });

                const channels = await ChatAPI.loadChannels();
                const {totalUnread} = ChatUtils.getUnreadMeta(channels);

                if (
                    !channels.length ||
                    (channels.length === 1 &&
                        channels[0].customType === ChatUtils.CHANNEL_TYPES.FOOD)
                ) {
                    // a member had a coach initiate a food logging chat with them before they ever used chat to create
                    // the 1 on 1 coaching channel, so go ahead and add that channel as a default here
                    channels.push(ChatUtils.createDummyCoachingChannel());
                }

                return {
                    channels: ChatUtils.orderChannels(channels),
                    hasUnreads: totalUnread > 0,
                };
            })
            .catch(error => {
                // create the dummy channel so that Chat displays correctly in the event that no channels are available
                return {
                    channels: [ChatUtils.createDummyCoachingChannel()],
                };
            }),
    };
};

export const chatUpdateUnreadCount = data => {
    return {
        type: CHAT_UPDATE_UNREAD_COUNT,
        payload: data,
    };
};

export const chatToggle = () => {
    return {
        type: CHAT_TOGGLE,
    };
};

export const chatUpdateMessages = data => {
    return {
        type: CHAT_UPDATE_MESSAGES,
        payload: data,
    };
};

export const chatMarkChannelRead = channel => {
    return {
        type: CHAT_MARK_CHANNEL_READ,
        payload: channel,
    };
};

export const chatLoadMessages = ({
    channel,
    channelMessages,
    isInitial = false,
}) => {
    return dispatch => {
        return dispatch({
            type: CHAT_LOAD_MESSAGES,
            payload: ChatAPI.loadMessages({
                channel,
                channelMessages,
                isInitial,
            }).then(response => {
                dispatch(chatMarkChannelRead(channel));

                return response;
            }),
        });
    };
};

export const chatSetCurrentChannel = channel => {
    return {
        type: CHAT_SET_CURRENT_CHANNEL,
        payload: channel,
    };
};

export const chatSetWaitingForNewCoach = isWaiting => {
    return {
        type: CHAT_SET_WAITING_FOR_NEW_COACH,
        payload: isWaiting,
    };
};
