import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Redirect, Route, useLocation} from 'react-router-dom';
import useTitle from '@teladoc/fe-ccm/ui/common/use-title';
import UserUtils from '@teladoc/fe-ccm/ui/user/user-utils';
import {getPrimaryRouteById} from './routes';

const dashboard = getPrimaryRouteById('dashboard');
const login = getPrimaryRouteById('login');
const preLogin = getPrimaryRouteById('prelogin');
const pending = getPrimaryRouteById('pending');

const RoutePrivate = ({id, programSlugs, component: Component, ...rest}) => {
    const location = useLocation();
    const {path, parentPath, isOneApp} = rest;
    const {isAuthenticated, isManuallyLoggedOut} = useSelector(
        state => state.user
    );
    const {isTeamsEnvironment, isRedirectFromTeams} = useSelector(
        state => state.app
    );
    const showBOTLogin = isTeamsEnvironment && isRedirectFromTeams;
    const showTeamsPreLogin = isTeamsEnvironment && !isRedirectFromTeams;
    const willRender = useMemo(
        () => UserUtils.willRender(programSlugs),
        [programSlugs]
    );
    // This pathname determines the path that will render if `willRender` is false.
    // `willRender` will be false if there's no active programs on the member's account.
    const pathname =
        isAuthenticated && !showBOTLogin
            ? pending.path
            : showTeamsPreLogin
            ? preLogin.path
            : login.path;

    useTitle({
        id,
        path,
        ...(parentPath && {parentPath}),
    });

    return (
        <Route id={id} {...rest}>
            {isAuthenticated && !showBOTLogin && willRender ? (
                <Component id={id} {...rest} />
            ) : isOneApp ? (
                // TODO: temporary fix to redirect user to signin page.
                ''
            ) : (
                <Redirect
                    to={{
                        pathname,
                        state: {
                            // check if user logged out manually in which case we should redirect the next login attempt to dashboard
                            from: isManuallyLoggedOut
                                ? dashboard.path
                                : location,
                        },
                    }}
                />
            )}
        </Route>
    );
};

RoutePrivate.propTypes = {
    id: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
        PropTypes.object,
    ]),
    programSlugs: PropTypes.array,
};

export default RoutePrivate;
