import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import {useEffect, useRef, useState, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import notify from '@teladoc/pulse/ui/Notification';
import IconArrowDownDefault from '@teladoc/pulse/icons/arrow-down-default.svg';
import IconChatDefault from '@teladoc/pulse/icons/chat-default.svg';
import IconProfileDefault from '@teladoc/pulse/icons/profile-default.svg';
import IconHelpDefault from '@teladoc/pulse/icons/help-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import Popover from '@teladoc/pulse/ui/Popover';
import ControlBubble from '@teladoc/fe-ccm/ui/common/control-bubble/ControlBubble';
import Logo from '@teladoc/fe-ccm/ui/common/logo/Logo';
import Chat from '@teladoc/fe-ccm/ui/chat/Chat';
import {chatConnect, chatToggle} from '@teladoc/fe-ccm/ui/chat/chat-actions';
import ChatUtils from '@teladoc/fe-ccm/ui/chat/chat-utils';
import ApptentiveUtils from '@teladoc/fe-ccm/ui/common/apptentive/apptentive-utils';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import {getPrimaryRouteById} from '@teladoc/fe-ccm/ui/router/routes';
import Hamburger from '@teladoc/fe-ccm/ui/common/hamburger/Hamburger';
import DOMUtils from '@livongo/utilities/system/dom';
import StorageUtils from '@livongo/utilities/system/storage';
import SubNavigation from '~/sidebar/navigation/SubNavigation';
import HelpDropdown from './help-dropdown/HelpDropdown';
import Onboarding from './onboarding/Onboarding';
import FoodLogIntroModal from './food-log-intro-modal/FoodLogIntroModal';
import css from './HeaderPrivate.scss';

const ONBOARDING_KEY = 'has-seen-intro-tour';
const FOOD_LOG_INTRO_KEY = 'has-seen-food-log-intro';
const dashboard = getPrimaryRouteById('dashboard').path;
const screenWidth = DOMUtils.width('body');
// the main onboarding and food log intro are only shown
// on screens equal or larger than $breakpoint-lg: 1025px
// this doesn't apply to the main onboarding for members enrolled to 'chronic kidney disease whole person'
const isDesktop = screenWidth >= 1025;

const HeaderPrivate = ({
    isNavShowing,
    isSidebarShowing,
    onNavToggle,
    onLogoClick,
    isControlShowing,
}) => {
    const {pathname} = useLocation();
    const {push} = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {
        app: {hasChatAvailable},
        user: {isAuthenticated, firstName, activePrograms},
        chat: {
            isShowing: isChatShowing,
            hasUnread: {chronicCare: hasUnreadChats},
            channels,
        },
    } = useSelector(({app, chat, user}) => ({app, chat, user}));
    const userHasUnreadChats = useRef(hasUnreadChats);
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [showFoodLogModal, setShowFoodLogModal] = useState(false);
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const messageCoach = t('header.messageCoach');
    const onChatOpen = () => {
        MixpanelUtils.track({event: 'chat.header.icon.clicked'});
        ApptentiveUtils.event('chat.homescreen.icon.clicked');
        dispatch(chatToggle());
    };
    const onChatToggle = () => {
        dispatch(chatToggle());
    };
    const onOnboardingHide = () => {
        setShowOnboarding(false);
    };
    const onFoodLogModalConfirm = () => {
        MixpanelUtils.track({event: 'newfeaturetour.modal.confirm.clicked'});

        setShowFoodLogModal(false);
        push(getPrimaryRouteById('food').path);
    };
    const onFoodLogModalClose = () => {
        setShowFoodLogModal(false);
    };
    const onLanguageModalToggle = show => {
        setShowLanguageModal(show);
    };
    const onHelpDropdownToggle = isShowing => {
        if (isShowing) {
            MixpanelUtils.track({event: 'help.header.icon.clicked'});
        }
    };
    const onHelpDropdownItemClick = targetId => {
        if (targetId === 'tour') {
            // Adding some timeout moves focus to the modal instead of the help button
            setTimeout(() => {
                setShowOnboarding(true);
            }, 500);
        }
    };
    const onInitialLoad = useCallback(() => {
        const isDashboardPath = pathname === dashboard;

        if (!isDashboardPath) {
            return;
        }

        const hasSeenOnboarding = StorageUtils.get({key: ONBOARDING_KEY});
        const hasSeenFoodLogIntro = StorageUtils.get({key: FOOD_LOG_INTRO_KEY});
        const {chronicKidneyDisease, heartFailure} = activePrograms;
        const hasMainOnboarding =
            isDesktop || chronicKidneyDisease || heartFailure;
        const hasFoodLogOnboarding =
            isDesktop &&
            Object.values(
                pick(activePrograms, [
                    'bloodSugar',
                    'bloodPressure',
                    'prediabetes',
                    'weight',
                ])
            ).some(Boolean);

        if (!hasSeenOnboarding && hasMainOnboarding) {
            setShowOnboarding(true);

            return;
        }

        if (!hasSeenFoodLogIntro && hasFoodLogOnboarding) {
            setShowFoodLogModal(true);

            StorageUtils.set({key: FOOD_LOG_INTRO_KEY, value: true});
        }
    }, [pathname, activePrograms]);

    useEffect(() => {
        onInitialLoad();
    }, [onInitialLoad]);

    useEffect(() => {
        if (hasChatAvailable) {
            dispatch(chatConnect());
        }
    }, [dispatch, hasChatAvailable]);

    useEffect(() => {
        if (hasChatAvailable && userHasUnreadChats.current !== hasUnreadChats) {
            if (hasUnreadChats) {
                const {totalUnread, lastUnreadsFrom} =
                    ChatUtils.getUnreadMeta(channels);

                if (!isChatShowing && lastUnreadsFrom.length) {
                    notify({
                        type: 'success',
                        title: t(
                            `header.chat.notifications.unreadMessage.title`
                        ),
                        message: t(
                            `header.chat.notifications.unreadMessage.message`,
                            {
                                count: totalUnread,
                                firstName: lastUnreadsFrom[0],
                            }
                        ),
                    });
                }
            }

            userHasUnreadChats.current = hasUnreadChats;
        }
    }, [
        t,
        dispatch,
        channels,
        hasChatAvailable,
        hasUnreadChats,
        isChatShowing,
    ]);

    if (!isAuthenticated) {
        return null;
    }

    return (
        <header className={css.root}>
            <Hamburger
                isSidebarShowing={isSidebarShowing}
                onClick={onNavToggle}
            />
            <div className={css.logoContainer}>
                <Logo
                    width={146}
                    height={36}
                    className={css.logo}
                    onClick={onLogoClick}
                />
            </div>
            {isControlShowing && (
                <div className={css.controlsContainer}>
                    <div className={css.controls}>
                        <Popover
                            hideOnContentClick
                            hideOnOutsideClick
                            trigger={
                                <Button
                                    className={css.help}
                                    aria-label={t('header.help.action')}
                                >
                                    <ControlBubble>
                                        <IconHelpDefault />
                                    </ControlBubble>
                                    <span className={css.controlsCopy}>
                                        {t('header.help.action')}
                                    </span>
                                    <IconArrowDownDefault
                                        className={css.memberToggle}
                                    />
                                </Button>
                            }
                            content={
                                <FocusTrap
                                    focusTrapOptions={{
                                        clickOutsideDeactivates: true,
                                    }}
                                >
                                    <div>
                                        <HelpDropdown
                                            className={css.helpDropdown}
                                            onItemClick={
                                                onHelpDropdownItemClick
                                            }
                                        />
                                    </div>
                                </FocusTrap>
                            }
                            onToggle={onHelpDropdownToggle}
                        />
                        {hasChatAvailable && (
                            <Button
                                className={css.chat}
                                aria-label={messageCoach}
                                onClick={onChatOpen}
                            >
                                <ControlBubble>
                                    <IconChatDefault />
                                    {hasUnreadChats && (
                                        <span className={css.notifier} />
                                    )}
                                </ControlBubble>
                                <span className={css.controlsCopy}>
                                    {messageCoach}
                                </span>
                                <IconArrowDownDefault
                                    className={css.memberToggle}
                                />
                            </Button>
                        )}
                        <Popover
                            hideOnContentItemClick={[
                                'profilePreferences',
                                'reportsAndData',
                                'userGuides',
                                'programs',
                                'authorization',
                            ]}
                            hideOnOutsideClick={!showLanguageModal}
                            trigger={
                                <Button
                                    className={css.member}
                                    aria-label={t('header.userOptions')}
                                >
                                    <ControlBubble>
                                        <IconProfileDefault />
                                    </ControlBubble>
                                    <span className={css.controlsCopy}>
                                        {firstName}
                                    </span>
                                    <IconArrowDownDefault
                                        className={css.memberToggle}
                                    />
                                </Button>
                            }
                            content={
                                <FocusTrap
                                    active={!showLanguageModal}
                                    focusTrapOptions={{
                                        clickOutsideDeactivates: true,
                                    }}
                                >
                                    <div>
                                        <SubNavigation
                                            showLanguageModal={
                                                showLanguageModal
                                            }
                                            onLanguageModalToggle={
                                                onLanguageModalToggle
                                            }
                                            className={css.memberDropdown}
                                            itemClassName={css.dropdownItem}
                                            activeItemClassName={
                                                css.dropdownActiveItem
                                            }
                                        />
                                    </div>
                                </FocusTrap>
                            }
                        />
                    </div>
                </div>
            )}
            {isChatShowing && <Chat onClose={onChatToggle} />}
            {showOnboarding && (
                <Onboarding
                    isNavShowing={isNavShowing}
                    onHidden={onOnboardingHide}
                />
            )}
            <FoodLogIntroModal
                isOpen={showFoodLogModal}
                onConfirm={onFoodLogModalConfirm}
                onRequestClose={onFoodLogModalClose}
            />
        </header>
    );
};

HeaderPrivate.propTypes = {
    isNavShowing: PropTypes.bool,
    isSidebarShowing: PropTypes.bool,
    onNavToggle: PropTypes.func.isRequired,
    onLogoClick: PropTypes.func.isRequired,
    isControlShowing: PropTypes.bool,
};

export default HeaderPrivate;
