import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import StorageUtils from '@livongo/utilities/system/storage';
import MainOnboarding from '../main-onboarding/MainOnboarding';
import KidneyDiseaseOnboarding from '../kidney-disease-onboarding/KidneyDiseaseOnboarding';
import HeartFailureOnboarding from '../heart-failure-onboarding/HeartFailureOnboarding';

const ONBOARDING_KEY = 'has-seen-intro-tour';

const Onboarding = props => {
    const {activePrograms} = useSelector(state => state.user);
    const {
        heartFailure: hasHeartFailure,
        chronicKidneyDisease: hasKidneyDisease,
    } = activePrograms;

    useEffect(() => {
        if (hasHeartFailure) {
            // TODO: Add Mixpanel event
        } else if (hasKidneyDisease) {
            MixpanelUtils.track({event: 'firsttour.ckd.viewed'});
        } else {
            MixpanelUtils.track({
                event: 'firsttour.dashboard.prompt.viewed',
            });
        }

        StorageUtils.set({key: ONBOARDING_KEY, value: true});
    }, [hasHeartFailure, hasKidneyDisease]);

    return hasHeartFailure ? (
        <HeartFailureOnboarding {...props} />
    ) : hasKidneyDisease ? (
        <KidneyDiseaseOnboarding {...props} />
    ) : (
        <MainOnboarding {...props} />
    );
};

Onboarding.propTypes = {
    isNavShowing: PropTypes.bool.isRequired,
    onHidden: PropTypes.func.isRequired,
};

export default Onboarding;
