import {useRef, Suspense, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Redirect,
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import {
    useI18nContext,
    useG11nBidirectionalStateManager,
} from '@teladoc/pulse/ui/Context/i18n';
import UserUtils from '@teladoc/fe-ccm/ui/user/user-utils';
import {userUpdate} from '@teladoc/fe-ccm/ui/user/user-actions';
import ReactAxeCore from '@teladoc/fe-ccm/ui/common/axe-core/axe-core';
import {IS_PROD} from '@teladoc/fe-ccm/ui/config';
import Loader from '@teladoc/fe-ccm/ui/loader/Loader';
import {getStore} from '@teladoc/fe-ccm/ui/store';
import {appUpdate} from '@teladoc/fe-ccm/ui/app/app-actions';
import Arg from '@livongo/arg';
import {getPrimaryRouteById, userActionRoutes} from '../router/routes';
import App from './TeamsApp';
import css from './Root.scss';

const Root = () => {
    const dispatch = useDispatch();
    const {selectedLang} = useI18nContext();
    const userRoutes = userActionRoutes.filter(route => route.id !== 'logout');
    const {userLocale} = useSelector(state => state.user);
    const programId = useRef(Arg('program')?.toUpperCase());
    const PROGRAM_ROUTES = {
        DIABETES: 'bloodSugar',
        HYPERTENSION: 'bloodPressure',
        WEIGHT_MANAGEMENT: 'weight',
        PREDIABETES: null,
        // - Programs that don't have a specific URL will be redirected to /dashboard
        // - Behavioral health will be redirected to the myStrength website
        HEART_FAILURE: null,
        CHRONIC_KIDNEY_DISEASE: null,
    };
    const routeId = PROGRAM_ROUTES[programId.current];
    const dashboard = getPrimaryRouteById('dashboard');
    const loginSuccessLocation = routeId
        ? getPrimaryRouteById(routeId).path
        : dashboard.path;

    useG11nBidirectionalStateManager(
        userLocale,
        useCallback(
            code => {
                dispatch(userUpdate({userLocale: code}));
            },
            [dispatch]
        )
    );

    useEffect(() => {
        UserUtils.updateHeaders({'Accept-Language': selectedLang});
    }, [selectedLang]);

    useEffect(() => {
        document
            .querySelector('[data-theme="teladoc"] body')
            .setAttribute('data-teams', '');
    }, []);

    // in case of API errpr invalid_teams_token the initialdata is not set with isTeamsEnvironment to true. Hence calling appUpdate explicitly to update the store
    useEffect(() => {
        dispatch(appUpdate({isTeamsEnvironment: true}));
    }, [dispatch]);

    if (!IS_PROD && window) {
        window.getStore = getStore();
    }

    /**
     * Initializing @axe-core/react
     * https://github.com/dequelabs/axe-core-npm/blob/develop/packages/react/README.md
     */
    ReactAxeCore({loadScript: !IS_PROD});

    return (
        <Router>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to={loginSuccessLocation} />
                    </Route>
                    {userRoutes.map(({id, path, component: Component}) => {
                        return (
                            <Route
                                key={id}
                                exact
                                path={path}
                                render={props => (
                                    <div className={css.routePublic}>
                                        <Component
                                            {...props}
                                            isRedirectFromTeams // TODO: remove this prop after persistent login
                                        />
                                    </div>
                                )}
                            />
                        );
                    })}
                    <Route path="/" render={props => <App {...props} />} />
                </Switch>
            </Suspense>
        </Router>
    );
};

export default Root;
