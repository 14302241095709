import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {cnb} from 'cnbuilder';
import {Trans, useTranslation} from 'react-i18next';
import Linkify from 'react-linkify';
import ExternalPageLink from '@teladoc/fe-ccm/ui/common/external-page-link/ExternalPageLink';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import {useL10nDateTimeContext} from '@teladoc/pulse/ui/Context/l10n/DateTime';
import ScreenReader from '../../common/screen-reader/ScreenReader';
import {MEMBER_SUPPORT_URL} from '../../config';
import ChatUtils from '../chat-utils';
import css from './ChatCard.scss';

const MAX_TRUNCATE_LENGTH = 1000;
const {
    USER_TYPES: {MEMBER},
    MESSAGE_TYPES: {ADMIN},
    formatUserName,
    getInitials,
} = ChatUtils;

function truncate(input) {
    return input.length > MAX_TRUNCATE_LENGTH
        ? `${input.substring(0, MAX_TRUNCATE_LENGTH)}...`
        : input;
}

function linkifyDecorator(href, text, key) {
    return (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
            {text.endsWith('.pdf') ? (
                <ScreenReader type="pdf" />
            ) : (
                <ExternalPageLink className={css.externalLink} />
            )}
        </a>
    );
}

const Message = ({className, isFirstInteraction, data}) => {
    const {intlFormat} = useL10nDateTimeContext();
    const {t} = useTranslation();
    const {fullName, userLocale} = useSelector(state => state.user);
    const [isExpanded, setIsExpanded] = useState(false);
    const shouldTruncate = data?.message?.length > MAX_TRUNCATE_LENGTH;
    const userType = data?.sender?.metaData?.userType;
    const isAdmin = data?.messageType === ADMIN;
    const profileImage = get(data, 'sender.profileUrl', '');
    const onSeeMoreToggle = () => {
        setIsExpanded(expanded => !expanded);
    };

    const senderName =
        userType === MEMBER
            ? fullName
            : isAdmin && data
            ? JSON.parse(data?.data)?.senderName
            : data?.senderName;
    const {abbreviated, initials} = formatUserName(senderName);

    return (
        <div
            className={cnb(
                css.root,
                css.message,
                {
                    [css[
                        `message${
                            userType ? capitalize(userType.toLowerCase()) : ''
                        }`
                    ]]: !isFirstInteraction,
                },
                className
            )}
        >
            {isFirstInteraction ? (
                <div className={css.firstInteraction}>
                    <h2 className="heading-xs">
                        {t('header.chat.firstInteraction.title')}
                    </h2>
                    <p>{t('header.chat.firstInteraction.paragraph1')}</p>
                    <p>
                        <Trans
                            i18nKey="header.chat.firstInteraction.paragraph2"
                            components={[
                                /* eslint-disable react/jsx-key */
                                <a
                                    href={MEMBER_SUPPORT_URL[userLocale]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ExternalPageLink
                                        className={css.externalLink}
                                    />
                                </a>,
                                /* eslint-enable react/jsx-key */
                            ]}
                        />
                    </p>
                </div>
            ) : (
                <>
                    <div className={css.messageInfo}>
                        {!isEmpty(profileImage) ? (
                            <Image
                                classNameRoot={css.profileImage}
                                src={profileImage}
                                width={56}
                                height={56}
                                alt={t(`header.chat.messages.profile`, {
                                    name: senderName,
                                })}
                            />
                        ) : (
                            <div className={css.profileContainer}>
                                <p className={css.profileInitials}>
                                    {initials ||
                                        getInitials(data?.sender?.nickname)}
                                </p>
                            </div>
                        )}
                        <div className={css.messageMeta}>
                            <p>
                                {userType === MEMBER
                                    ? fullName
                                    : abbreviated || data?.sender?.nickname}
                            </p>
                            <span>
                                {intlFormat(new Date(data.createdAt), {
                                    month: 'short',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })}
                            </span>
                        </div>
                    </div>
                    <div className={css.messageContent}>
                        {isExpanded ? (
                            <>
                                {isAdmin ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data.message,
                                        }}
                                    />
                                ) : (
                                    <Linkify
                                        componentDecorator={linkifyDecorator}
                                    >
                                        {data.message}
                                    </Linkify>
                                )}
                                <Button
                                    className={css.messageExpand}
                                    onClick={onSeeMoreToggle}
                                >
                                    {t('header.chat.messages.seeLess')}
                                </Button>
                            </>
                        ) : isAdmin ? (
                            <>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: truncate(data.message),
                                    }}
                                />
                                {shouldTruncate && (
                                    <Button
                                        className={css.messageExpand}
                                        onClick={onSeeMoreToggle}
                                    >
                                        {t('header.chat.messages.seeMore')}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Linkify componentDecorator={linkifyDecorator}>
                                {truncate(data.message)}
                                {shouldTruncate && (
                                    <Button
                                        className={css.messageExpand}
                                        onClick={onSeeMoreToggle}
                                    >
                                        {t('header.chat.messages.seeMore')}
                                    </Button>
                                )}
                            </Linkify>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

Message.propTypes = {
    className: PropTypes.string,
    isFirstInteraction: PropTypes.bool,
    data: PropTypes.object,
};

export default Message;
