import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import Tooltip from './tour-tooltip/TourTooltip';
import TourUtils from './tour-utils';
import './Tour.scss';

const {
    ACTIONS: {CLOSE, RESET},
} = TourUtils;

const Tour = ({
    steps,
    locale,
    continuous,
    hideBackButton,
    disableOverlay,
    disableScrolling,
    disableOverlayClose,
    styles,
    floaterProps,
    onChange,
    onClose,
}) => {
    const onStepChange = data => {
        const {action} = data;

        if (onChange) {
            onChange(data);
        }

        if ((action === CLOSE || action === RESET) && onClose) {
            onClose(data);
        }
    };

    return (
        <Joyride
            steps={steps}
            locale={locale}
            styles={styles}
            continuous={continuous}
            hideBackButton={hideBackButton}
            disableOverlay={disableOverlay}
            disableScrolling={disableScrolling}
            disableOverlayClose={disableOverlayClose}
            disableScrollParentFix
            tooltipComponent={Tooltip}
            callback={onStepChange}
            floaterProps={floaterProps}
        />
    );
};

Tour.propTypes = {
    /** An array with the content for each tooltip shown on the tour. */
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            /** The javascript query to select an elemnt on the DOM. */
            target: PropTypes.string.isRequired,
            /** The title of the tooltip. */
            title: PropTypes.string.isRequired,
            /** The description paragraph of the tooltip. */
            description: PropTypes.string.isRequired,
            /** The image ID that is stored in Cloudinary for this image. */
            cloudinaryImageId: PropTypes.string,
            /** The position where the tooltip will be shown relative to the target. */
            placement: PropTypes.oneOf(['bottom', 'top', 'right', 'left']),
            /** A boolean to control whether to show a blinking beacon indicating the next step tour. */
            disableBeacon: PropTypes.bool,
        })
    ),
    /** An object with the labels for the action button of the tour tooltip. */
    locale: PropTypes.shape({
        /** The label for the button "back". */
        back: PropTypes.string,
        /** The label for the button "close" of a single-step tour. */
        close: PropTypes.string,
        /** The label for the "last" action button of a multi-step tour. */
        last: PropTypes.string,
        /** The label for the button "next". */
        next: PropTypes.string,
        /** The label for the button "skip". */
        skip: PropTypes.string,
    }),
    /** A boolean to control if the next tooltip will be shown right after closing the previos one. */
    continuous: PropTypes.bool,
    /** A boolean to control if the back button should be hidden. */
    hideBackButton: PropTypes.bool,
    /** A boolean to control if the background shim should be visible. */
    disableOverlay: PropTypes.bool,
    /** A boolean to control if the screen should auto scroll to the current target area. */
    disableScrolling: PropTypes.bool,
    /** A boolean to control if the tour should close upon clicking on the background shim. */
    disableOverlayClose: PropTypes.bool,
    /** A object to overwrite the defaut tour styles https://docs.react-joyride.com/styling. */
    styles: PropTypes.shape({
        options: PropTypes.object,
    }),
    /** A object to overwrite the defaut floater properties https://github.com/gilbarbara/react-floater. react-floater is another tooltip library which react-joy was built on top */
    floaterProps: PropTypes.object,
    /** A callback function called after the tour step changes. */
    onChange: PropTypes.func,
    /** A callback function called after the tour is closed. */
    onClose: PropTypes.func.isRequired,
};

Tour.defaultProps = {
    styles: {
        options: {
            zIndex: 700, // same level of a modal shim z-index
        },
    },
};

export default Tour;
