import {cnb} from 'cnbuilder';
import {useTranslation} from 'react-i18next';
import ExternalPageLink from '@teladoc/fe-ccm/ui/common/external-page-link/ExternalPageLink';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import MixpanelUtils from '../../../../common/utilities/mix-panel';
import {
    PRIVACY_PRACTICES_URL,
    TERMS_OF_SERVICE_URL,
    NON_DISCRIMINATION_URL,
    CONSENT_AGREEMENT_URL,
} from '../../../../config';
import css from './TransitionFooter.scss';

const TransitionFooter = () => {
    const {selectedLang} = useI18nContext();
    const {t} = useTranslation();

    const handleLinkClick = property => {
        MixpanelUtils.track('footer.button.clicked', {
            button: property,
        });
    };

    return (
        <footer className={css.root}>
            <div className={cnb(css.container, css.privateContainer)}>
                <div className={css.footerTitle}>
                    <div className={css.company}>
                        &copy; 2002-2023 Teladoc, Inc. All rights reserved.
                    </div>
                </div>

                <div className={css.linksWrapper}>
                    <p className={css.links}>
                        <span className={css.link}>
                            <a
                                href={PRIVACY_PRACTICES_URL[selectedLang]}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() =>
                                    handleLinkClick(
                                        'Notice of Privacy Practices'
                                    )
                                }
                                aria-label={`
                            ${t('footer.privacyPractices')}, 
                            ${t('screenReader.externalWebPage')}`}
                            >
                                {t('footer.privacyPractices')}
                            </a>
                            <ExternalPageLink className={css.externalLink} />
                        </span>
                        <span className={css.link}>
                            <a
                                href={TERMS_OF_SERVICE_URL[selectedLang]}
                                className={css.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() =>
                                    handleLinkClick('Terms of Service')
                                }
                                aria-label={`
                            ${t('footer.terms')}, ${t(
                                    'screenReader.externalWebPage'
                                )}`}
                            >
                                {t('footer.terms')}
                            </a>
                            <ExternalPageLink className={css.externalLink} />
                        </span>
                        <span className={css.link}>
                            <a
                                href={NON_DISCRIMINATION_URL[selectedLang]}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() =>
                                    handleLinkClick(
                                        'Notice of Non-Discrimination and Language Assistance'
                                    )
                                }
                                aria-label={`
                            ${t('footer.nonDiscrimination')}, ${t(
                                    'screenReader.externalWebPage'
                                )}`}
                            >
                                {t('footer.nonDiscrimination')}
                            </a>
                            <ExternalPageLink className={css.externalLink} />
                        </span>
                        <span className={css.link}>
                            <a
                                href={CONSENT_AGREEMENT_URL}
                                className={css.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() =>
                                    handleLinkClick(
                                        'Informed Consent Agreement'
                                    )
                                }
                                aria-label={`
                                    ${t('footer.consentAgreement')},  
                                    ${t('screenReader.externalWebPage')}`}
                            >
                                {t('footer.consentAgreement')}
                            </a>
                            <ExternalPageLink className={css.externalLink} />
                        </span>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default TransitionFooter;
