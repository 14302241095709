import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import {NavLink as ReactRouterLink} from 'react-router-dom';

const Link = ({className, forceAnchor, to, children, onClick, ...props}) => {
    if (forceAnchor || includes(to, 'http')) {
        if (props.activeClassName) {
            delete props.activeClassName;
        }

        return (
            <a className={className} href={to} onClick={onClick} {...props}>
                {children}
            </a>
        );
    } else {
        return (
            <ReactRouterLink
                className={className}
                to={to}
                onClick={onClick}
                {...props}
            >
                {children}
            </ReactRouterLink>
        );
    }
};

Link.propTypes = {
    className: PropTypes.string,
    forceAnchor: PropTypes.bool,
    to: PropTypes.string,
    children: PropTypes.node,
};

export default Link;
