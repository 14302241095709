import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import {cnb} from 'cnbuilder';
import useTitle from '@teladoc/fe-ccm/ui/common/use-title';
import Card from '@teladoc/fe-ccm/ui/common/card/Card';
import {DESKTOP_SCREEN_SIZE} from '@teladoc/fe-ccm/ui/config';
import DOMUtils from '@livongo/utilities/system/dom';
import HeaderPublic from '~/header/HeaderPublic';
import FooterPublic from '~/footer/FooterPublic';
import css from './RoutePublic.scss';

const RoutePublic = ({id, exact, path, component: Component}) => {
    useTitle({id, path, isPublicRoute: true});
    const isAtHomeLab = id.includes('nonMember');
    const screenWidth = DOMUtils.width('body');
    const isLargeScreenSize = screenWidth >= DESKTOP_SCREEN_SIZE;

    return (
        <Route
            exact
            path={path}
            render={props => (
                <div
                    className={cnb(
                        css.root,
                        !isLargeScreenSize ? css.noBackgroundColor : ''
                    )}
                >
                    <HeaderPublic />
                    <Card
                        className={cnb(
                            css.card,
                            {
                                [css.cardMidSize]: isAtHomeLab,
                            },
                            !isLargeScreenSize ? css.noBoxShadow : ''
                        )}
                    >
                        <Component {...props} />
                    </Card>
                    <FooterPublic />
                </div>
            )}
        />
    );
};

RoutePublic.propTypes = {
    id: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
        PropTypes.object,
    ]).isRequired,
};

export default RoutePublic;
