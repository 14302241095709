import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import isUndefined from 'lodash/isUndefined';
import {useRef, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useL10nDateTimeContext} from '@teladoc/pulse/ui/Context/l10n/DateTime';
import useMounted from '@livongo/utilities/hooks/useMounted';
import Loader from '../../loader/Loader';
import MixpanelUtils from '../../common/utilities/mix-panel';
import ApiError from '../../common/api-error/ApiError';
import CommonUtils from '../../common/utilities/common-utils';
import UnsupportedBrowserModal from '../../common/unsupported-browser-modal/UnsupportedBrowserModal';
import useApi from '../../common/use-api';
import {
    chatLoadMessages,
    chatUpdateMessages,
    chatSetWaitingForNewCoach,
} from '../chat-actions';
import ChatAPI from '../chat-api';
import ChatUtils from '../chat-utils';
import MemberError from '../cards/MemberError';
import Message from '../cards/Message';
import Status from '../cards/Status';
import MessageEntry from './message-entry/MessageEntry';
import css from './Messages.scss';

const MESSAGE_ITEM = 'Message-item';
const {
    CHANNEL_TYPES: {FOOD},
    MESSAGE_ITEM_TYPES: {DATE, LOADER, UNREAD_MARKER},
    STATUS_TYPES: {CONNECTING, CONNECTING_NEW_COACH, SENT, UNAVAILABLE},
    USER_TYPES: {COACH},
    getCoachAvailability,
    getLastMemberSentMessage,
    getUsersInChannel,
    prepareChannelItems,
} = ChatUtils;

function scrollToFirstMessage(content) {
    // avoid trying to execute this if the divs are no longer in the DOM
    if (!content) {
        return;
    }

    content.scrollTop = content.scrollHeight;
}

function scrollToSavedPosition(container, item) {
    // avoid trying to execute this if the divs are no longer in the DOM
    if (!container || !item) {
        return;
    }

    // 80 px gives a bit of extra padding at the top so the scroll position isn't hugging the top of the chat
    container.scrollTop = item.offsetTop - 80;
}

function renderUnavailableStatus({
    channel,
    isAwaitingCoach,
    onConfirmClick,
    onDismissClick,
}) {
    // food logging doesn't show unavailable status messages
    if (channel.customType === FOOD) {
        return null;
    }

    const {isAvailable, name, status} = getCoachAvailability({
        channel,
        isAwaitingCoach,
    });

    if (isAvailable || !status) {
        return null;
    }

    MixpanelUtils.track({
        event: 'chat.chatscreen.awaymessage.shown',
    });

    return (
        <Status
            type={UNAVAILABLE}
            coachName={name}
            availability={status}
            onConfirmClick={onConfirmClick}
            onDismissClick={onDismissClick}
        />
    );
}

function renderItems({t, intlFormat, channel, channelMessages}) {
    if (isEmpty(channelMessages) || !channelMessages[channel.name]) {
        return;
    }

    const {messages, query} = channelMessages[channel.name];
    const items = prepareChannelItems({
        intlFormat,
        channel,
        messages,
        hasMoreMessages: query.hasMore,
    });

    return items.map(({type = '', value = ''} = {}) => {
        switch (type) {
            case LOADER:
                return <Loader key={LOADER} />;
            case DATE:
                return (
                    <p key={value} className={css.dateHeader}>
                        {value}
                    </p>
                );
            case UNREAD_MARKER:
                return (
                    <div key={UNREAD_MARKER} className={css.unreadMarker}>
                        <span>{t('header.chat.messages.unread')}</span>
                    </div>
                );
            default:
                return (
                    <Message
                        key={value?.messageId}
                        className={MESSAGE_ITEM}
                        data={value}
                    />
                );
        }
    });
}

const Messages = ({channel, onBackToChannels}) => {
    const {intlFormat} = useL10nDateTimeContext();
    const contentRef = useRef();
    const containerRef = useRef();
    const {t} = useTranslation();
    const {isMounted} = useMounted();
    const prevIsLoadingMessages = useRef(false);
    const dispatch = useDispatch();
    const {
        isLoading: isLoadingMessages,
        isWaitingForNewCoach,
        hasCoachRepliedActiveSession,
        channelMessages,
        messagesKey,
    } = useSelector(state => state.chat);
    const {isFirstInteraction: isFirst, customType} = channel;
    const [isFirstInteraction, setIsFirstInteraction] = useState(
        Boolean(isFirst)
    );
    const lastScrolledMessageItem = useRef(null);
    const [showUnsupportedBrowserModal, setShowUnsupportedBrowserModal] =
        useState(CommonUtils.isIE());
    const [hasLoadedInitialMessages, setHasLoadedInitialMessages] =
        useState(false);
    const [hasMemberError, setHasMemberError] = useState(false);
    const [isFirstMessageSent, setIsFirstMessageSent] = useState(false);
    const [isAwaitingCoach, setIsAwaitingCoach] = useState(false);
    const [isWaitingForFirstCoachReply, setIsWaitingForFirstCoachReply] =
        useState(false);
    const [showWaitingForNewCoach, setShowWaitingForNewCoach] =
        useState(isWaitingForNewCoach);
    const [showUnavailableStatus, setShowUnavailableStatus] = useState(true);
    const [selectedTalkToNewCoach, setSelectedTalkToNewCoach] = useState(false);
    const onDataLoaded = useCallback(
        metaData => {
            if (!metaData) {
                return;
            }

            if (isMounted) {
                // checking for a pinged_message_id is an explicit way
                // for us to check for a users first time interacting
                const hasNoPingedMessageId = isUndefined(
                    metaData.pinged_message_id
                );

                // Food logging channel types do not contain a pingedMessageId in metaData response
                if (channel.customType !== ChatUtils.CHANNEL_TYPES.FOOD) {
                    setIsFirstInteraction(hasNoPingedMessageId);
                }

                const {awaiting_coach: awaitingCoach} = metaData;
                const coachesInChannel = getUsersInChannel({
                    channel,
                    type: COACH,
                });
                const isAwaiting = JSON.parse(awaitingCoach);
                const isAwaitingNew =
                    isAwaiting && Boolean(coachesInChannel.length);

                setIsAwaitingCoach(isAwaiting);
                setIsWaitingForFirstCoachReply(
                    isAwaiting && !coachesInChannel.length
                );
                setShowWaitingForNewCoach(isAwaitingNew);

                dispatch(chatSetWaitingForNewCoach(isAwaitingNew));
            }
        },
        [dispatch, isMounted, channel]
    );
    const {isLoading, error: apiError} = useApi(
        {
            ...(isFirstInteraction
                ? {api: [], params: []}
                : {
                      api: ChatAPI.getChannelMeta,
                      params: {channel},
                      onLoad: onDataLoaded,
                  }),
        },
        [isFirstInteraction]
    );
    const onScroll = evt => {
        if (isEmpty(channelMessages) || isLoadingMessages) {
            return;
        }

        const {query} = channelMessages[channel.name];

        if (!query.hasMore) {
            return;
        }

        // if the user gets 200 pixels from the top, start loading the next set of messages
        if (containerRef.current.scrollTop < 200) {
            const messageEls = document.querySelectorAll(
                `.${css.content} .${MESSAGE_ITEM}`
            );

            // save the position of the last visible item so we can scroll back to it after the new messages load
            lastScrolledMessageItem.current = messageEls[messageEls.length - 1];

            dispatch(chatLoadMessages({channel, channelMessages}));
        }
    };
    const onFirstInteractionComplete = createdChannel => {
        setIsFirstMessageSent(true);

        if (createdChannel) {
            dispatch(
                chatUpdateMessages({
                    channel: createdChannel,
                    message: createdChannel.lastMessage,
                    isFirstInteraction: true,
                })
            );
        } else {
            setIsFirstInteraction(false);
        }

        setIsFirstInteraction(false);
        scrollToFirstMessage(contentRef.current);
    };
    const onMessageSent = message => {
        dispatch(
            chatUpdateMessages({
                channel,
                message,
            })
        );

        if (isFirstMessageSent) {
            setIsFirstMessageSent(false);
        }

        if (isWaitingForFirstCoachReply) {
            setIsWaitingForFirstCoachReply(false);
        }

        if (showUnavailableStatus) {
            setShowUnavailableStatus(false);
        }

        if (selectedTalkToNewCoach) {
            setSelectedTalkToNewCoach(false);
        }
    };
    const onDismissFirstMessage = () => {
        setIsFirstMessageSent(false);
        setIsWaitingForFirstCoachReply(false);
    };
    const onDismissWaitingForFirstCoachReply = () => {
        setIsWaitingForFirstCoachReply(false);
    };
    const onTalkToNewCoach = async () => {
        MixpanelUtils.track({
            event: 'chat.chatscreen.awaymessage.yes.clicked',
        });

        setShowUnavailableStatus(false);
        setSelectedTalkToNewCoach(true);

        try {
            await ChatAPI.requestCoach({
                channelType: customType,
                lastMessage: getLastMemberSentMessage(
                    channelMessages[channel.name].messages
                ),
            });

            if (isMounted) {
                setShowWaitingForNewCoach(true);
            }
        } catch (error) {
            // fail silently
        }
    };
    const onDismissUnavailableCoach = () => {
        MixpanelUtils.track({
            event: 'chat.chatscreen.awaymessage.no.clicked',
        });

        setShowUnavailableStatus(false);
    };
    const onDismissConnectingNewCoach = () => {
        setShowWaitingForNewCoach(false);
        setShowUnavailableStatus(false);
    };
    const onUnsupportedBrowserHidden = () => {
        setShowUnsupportedBrowserModal(false);
    };

    useEffect(() => {
        const members = getUsersInChannel({channel});

        // if for whatever reason there are multiple members in this channel, show an error and ensure nothing can be read
        if (members.length > 1) {
            setHasMemberError(true);
        } else if (!isFirstInteraction) {
            // load messages if this isn't the user's first use of chat
            dispatch(
                chatLoadMessages({
                    channel,
                    channelMessages,
                    isInitial: true,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setShowWaitingForNewCoach(isWaitingForNewCoach);
    }, [isWaitingForNewCoach]);

    useEffect(() => {
        // if the user sends their first messages and we show any waiting for coach card and a coach replies before the user
        // leaves the screen, set the waiting statuses to false so that we no longer show them when the new message from the coach is received
        if (hasCoachRepliedActiveSession) {
            setIsFirstMessageSent(false);
            setIsWaitingForFirstCoachReply(false);
            setShowWaitingForNewCoach(false);
        }
    }, [hasCoachRepliedActiveSession]);

    useEffect(() => {
        scrollToFirstMessage(contentRef.current);
    }, [messagesKey]);

    useEffect(() => {
        if (!hasLoadedInitialMessages) {
            if (isLoadingMessages && !prevIsLoadingMessages.current) {
                // the initial messages for a user that has them have been loaded
                prevIsLoadingMessages.current = true;
                setHasLoadedInitialMessages(true);

                setTimeout(() => {
                    scrollToFirstMessage(contentRef.current);
                }, 1000);
            }
        } else {
            // isLoadingMessages state changes when chatLoadMessages is called (initial load and scrolling to top of chat
            // window when there are more messages to load in the query)
            if (isLoadingMessages && !prevIsLoadingMessages.current) {
                prevIsLoadingMessages.current = true;
            } else if (!isLoadingMessages && prevIsLoadingMessages.current) {
                scrollToSavedPosition(
                    containerRef.current,
                    lastScrolledMessageItem.current
                );

                prevIsLoadingMessages.current = false;
            }
        }
    }, [isLoadingMessages, hasLoadedInitialMessages]);

    return (
        <div className={css.root}>
            {hasMemberError ? (
                <MemberError onActionClick={onBackToChannels} />
            ) : (
                <>
                    <div ref={containerRef} className={css.container}>
                        <div
                            ref={contentRef}
                            className={css.content}
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            tabIndex={0}
                            onScroll={throttle(onScroll, 500)}
                        >
                            {isLoading && <Loader />}
                            {apiError && <ApiError />}
                            {!isLoading && !apiError && (
                                <>
                                    <UnsupportedBrowserModal
                                        isOpen={showUnsupportedBrowserModal}
                                        onRequestClose={
                                            onUnsupportedBrowserHidden
                                        }
                                    />
                                    {isFirstInteraction ? (
                                        <>
                                            <Message isFirstInteraction />
                                            {renderItems({
                                                t,
                                                intlFormat,
                                                channel,
                                                channelMessages,
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {isFirstMessageSent && (
                                                <Status
                                                    type={SENT}
                                                    onDismissClick={
                                                        onDismissFirstMessage
                                                    }
                                                />
                                            )}
                                            {!isFirstMessageSent &&
                                                isWaitingForFirstCoachReply &&
                                                customType !== FOOD && (
                                                    <Status
                                                        type={CONNECTING}
                                                        onDismissClick={
                                                            onDismissWaitingForFirstCoachReply
                                                        }
                                                    />
                                                )}
                                            {showWaitingForNewCoach &&
                                                customType !== FOOD && (
                                                    <Status
                                                        type={
                                                            CONNECTING_NEW_COACH
                                                        }
                                                        onDismissClick={
                                                            onDismissConnectingNewCoach
                                                        }
                                                    />
                                                )}
                                            {!isFirstMessageSent &&
                                                !showWaitingForNewCoach &&
                                                showUnavailableStatus &&
                                                customType !== FOOD &&
                                                renderUnavailableStatus({
                                                    channel,
                                                    isAwaitingCoach,
                                                    onConfirmClick:
                                                        onTalkToNewCoach,
                                                    onDismissClick:
                                                        onDismissUnavailableCoach,
                                                })}
                                            {renderItems({
                                                t,
                                                intlFormat,
                                                channel,
                                                channelMessages,
                                            })}
                                        </>
                                    )}
                                    <div className={css.messageSpacer} />
                                </>
                            )}
                        </div>
                    </div>
                    <MessageEntry
                        channel={channel}
                        selectedTalkToNewCoach={selectedTalkToNewCoach}
                        onFirstInteractionComplete={onFirstInteractionComplete}
                        isFirstInteraction={isFirstInteraction}
                        onMessageSent={onMessageSent}
                    />
                </>
            )}
        </div>
    );
};

Messages.propTypes = {
    channel: PropTypes.object.isRequired,
    onBackToChannels: PropTypes.func.isRequired,
};

export default Messages;
