import PropTypes from 'prop-types';
import ExternalLinkDefault from '@teladoc/pulse/icons/external-link-default.svg';
import ScreenReader from '../screen-reader/ScreenReader';
import css from './ExternalPageLink.scss';

const ExternalPageLink = ({className}) => {
    return (
        <>
            <ExternalLinkDefault class={className} />
            <ScreenReader type="externalWebPage" />
        </>
    );
};

ExternalPageLink.propTypes = {
    className: PropTypes.string,
};

ExternalPageLink.defaultProps = {
    className: css.defaultExternalLink,
};

export default ExternalPageLink;
