import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import {cnb} from 'cnbuilder';
import {useTranslation} from 'react-i18next';
import IconCheckDefault from '@teladoc/pulse/icons/check-default.svg';
import IconEllipsisDefault from '@teladoc/pulse/icons/ellipsis-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import ChatUtils from '../chat-utils';
import css from './ChatCard.scss';

const {
    STATUS_TYPES: {CONNECTING, CONNECTING_NEW_COACH, SENT, UNAVAILABLE},
} = ChatUtils;
const ICONS = {
    [SENT]: IconCheckDefault,
    [CONNECTING]: IconCheckDefault,
    [CONNECTING_NEW_COACH]: IconCheckDefault,
    [UNAVAILABLE]: IconEllipsisDefault,
};

const Status = ({
    type,
    coachName,
    availability,
    onConfirmClick,
    onDismissClick,
}) => {
    const {t} = useTranslation();
    const Icon = ICONS[type];
    const i18nKey = `header.chat.statuses.${type}${
        availability ? `.${camelCase(availability)}` : ''
    }`;

    return (
        <div
            className={cnb(
                css.root,
                css.status,
                css[`status${capitalize(type)}`]
            )}
        >
            <div className={css.iconContainer}>
                <Icon className={css.icon} />
            </div>
            <div className={css.content}>
                <h2 className="heading-xs">
                    {t(`${i18nKey}.title`, {name: coachName})}
                </h2>
                <p>{t(`${i18nKey}.description`, {name: coachName})}</p>
                {type === UNAVAILABLE && (
                    <Button
                        className={css.statusConfirm}
                        onClick={onConfirmClick}
                    >
                        {t(`${i18nKey}.confirm`)}
                    </Button>
                )}
                <Button onClick={onDismissClick}>
                    {t(`${i18nKey}.dismiss`)}
                </Button>
            </div>
        </div>
    );
};

Status.propTypes = {
    type: PropTypes.oneOf(Object.values(ChatUtils.STATUS_TYPES)),
    coachName: PropTypes.string,
    availability: PropTypes.string,
    onConfirmClick: PropTypes.func,
    onDismissClick: PropTypes.func,
};

export default Status;
