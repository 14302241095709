import isArray from 'lodash/isArray';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

function getNamespace(path) {
    if (!path) return '';

    if (path.includes('/condition-management/')) {
        return path.substring(22);
    }

    return path[0] === '/' ? path.substring(1) : path;
}

export default function useTransLoader(path) {
    const namespaces = isArray(path)
        ? path.map(item => getNamespace(item))
        : getNamespace(path);
    const {t, i18n} = useTranslation(namespaces, {useSuspense: true});

    useEffect(() => {
        i18n.loadNamespaces(namespaces);
    }, [i18n, namespaces]);

    return {
        t,
        i18n,
    };
}
